import ReportsController from '../services/reports.service'
import {error as errorNotification, success as successNotification} from 'react-notification-system-redux';
import saveAs from 'file-saver';
import salesConstants from "../constants/sales.constants";

const months = [{value: 1, label: 'Enero'}, {value: 2, label: 'Febrero'}, {value: 3, label: 'Marzo'}, {value: 4, label: 'Abril'}, {value: 5, label: 'Mayo'}, {value: 6, label: 'Junio'},
    {value: 7, label: 'Julio'}, {value: 8, label: 'Agosto'}, {value: 9, label: 'Septiembre'}, {value: 10, label: 'Octubre'}, {value: 11, label: 'Noviembre'}, {value: 12, label: 'Diciembre'}];
export const actionTypes = {
    GET_PURCHASES_BOOK_LINK: 'GET_PURCHASES_BOOK_LINK',
    GET_PURCHASES_BOOK_LINK_REQUEST: 'GET_PURCHASES_BOOK_LINK_REQUEST',
    GET_PURCHASES_BOOK_LINK_SUCCESS: 'GET_PURCHASES_BOOK_LINK_SUCCESS',
    GET_PURCHASES_BOOK_LINK_ERROR: 'GET_PURCHASES_BOOK_LINK_ERROR',

    GET_SALES_BOOK_LINK: 'GET_SALES_BOOK_LINK',
    GET_SALES_BOOK_LINK_REQUEST: 'GET_SALES_BOOK_LINK_REQUEST',
    GET_SALES_BOOK_LINK_SUCCESS: 'GET_SALES_BOOK_LINK_SUCCESS',
    GET_SALES_BOOK_LINK_ERROR: 'GET_SALES_BOOK_LINK_ERROR',


    GET_EXECUTIVE_REPORT_LINK: 'GET_EXECUTIVE_REPORT_LINK',
    GET_EXECUTIVE_REPORT_LINK_REQUEST: 'GET_EXECUTIVE_REPORT_LINK_REQUEST',
    GET_EXECUTIVE_REPORT_LINK_SUCCESS: 'GET_EXECUTIVE_REPORT_LINK_SUCCESS',
    GET_EXECUTIVE_REPORT_LINK_ERROR: 'GET_EXECUTIVE_REPORT_LINK_ERROR',
};

const downloadInvoiceSuccess = invoice => ({ type: salesConstants.DOWNLOAD_INVOICE_SUCCESS, invoice });
const downloadInvoiceFailure = error => ({ type: salesConstants.DOWNLOAD_INVOICE_ERROR, error });

const getPurchasesBookLinkRequest = () => ({type: actionTypes.GET_PURCHASES_BOOK_LINK_REQUEST});
const getPurchasesBookLinkSuccess = link => ({type: actionTypes.GET_PURCHASES_BOOK_LINK_SUCCESS, link,});
const getPurchasesBookLinkError = error => ({type: actionTypes.GET_PURCHASES_BOOK_LINK_ERROR, error,});

const getSalesBookLinkRequest = () => ({type: actionTypes.GET_SALES_BOOK_LINK_REQUEST});
const getSalesBookLinkSuccess = link => ({type: actionTypes.GET_SALES_BOOK_LINK_SUCCESS, link,});
const getSalesBookLinkError = error => ({type: actionTypes.GET_SALES_BOOK_LINK_ERROR, error,});

const getExecutiveReportLinkRequest = () => ({type: actionTypes.GET_EXECUTIVE_REPORT_LINK_REQUEST});
const getExecutiveReportLinkSuccess = link => ({type: actionTypes.GET_EXECUTIVE_REPORT_LINK_SUCCESS, link,});
const getExecutiveReportLinkError = error => ({type: actionTypes.GET_EXECUTIVE_REPORT_LINK_ERROR, error,});

const notification = (title, message, error) => dispatch => {
    if (!error) {
        dispatch(successNotification({title, message}));
    } else {
        dispatch(errorNotification({title, message}));
    }
    return true;
};

const formatDate = date => {
    return `${date.getDate()} de ${months[date.getMonth()].label} de ${date.getFullYear()}`;
};

export const getPurchasesBookLink = (start, end, type) => async (dispatch) => {
    dispatch(getPurchasesBookLinkRequest());
    const extension = type === 'pdf' ? 'pdf': 'xlsx';
    const name = `Libro de compras del ${formatDate(start)} al ${formatDate(end)}.${extension}`;
    const mime = type === 'pdf' ? 'application/pdf' : 'application/vnd.ms-excel';
    try{
        const result = await ReportsController.getPurchasesBookLink(start.getTime(), end.getTime(), type);
        dispatch(getPurchasesBookLinkSuccess(result));
        dispatch(downloadFile(result, name, mime));
        dispatch(clearPurchasesBookLink());
    }catch (error){
        if (error.response.data) {
            dispatch(getPurchasesBookLinkError(error.response.data));
            dispatch(notification("Reportes", error.response.data.message, true));
        }
        dispatch(getPurchasesBookLinkError({message: "Error, intente más tarde"}));
    }
};

export const clearPurchasesBookLink = () => dispatch => {
       dispatch(getPurchasesBookLinkSuccess(null));
};


export const getSalesBookLink = (start, end, type) => async (dispatch) => {
    dispatch(getSalesBookLinkRequest());
    const extension = type === 'pdf' ? 'pdf': 'xlsx';
    const name = `Libro de ventas del ${formatDate(start)} al ${formatDate(end)}.${extension}`;
    const mime = type === 'pdf' ? 'application/pdf' : 'application/vnd.ms-excel';
    try{
        const result = await ReportsController.getSalesBookLink(start.getTime(), end.getTime(), type);
        dispatch(getSalesBookLinkSuccess(result));
        dispatch(downloadFile(result, name, mime));
        dispatch(clearSalesBookLink())
    }catch (error){
        if (error.response.data) {
            dispatch(getSalesBookLinkError(error.response.data));
            dispatch(notification("Reportes", error.response.data.message, true));
        }
        dispatch(getSalesBookLinkError({message: "Error, intente más tarde"}));
    }
};

export const clearSalesBookLink = () => dispatch => {
    dispatch(getPurchasesBookLinkSuccess(null));
};


export const getExecutiveReport = (year, month) => async (dispatch) => {
    dispatch(getExecutiveReportLinkRequest());
    const extension = 'pdf';
    const name = `Reporte Ejecutivo al ${formatDate(new Date(year, month, 0))}.${extension}`;
    const mime = 'application/pdf';
    try{
        const result = await ReportsController.getExecutiveReportLink(year,month);
        dispatch(getExecutiveReportLinkSuccess(result));
        dispatch(downloadFile(result, name, mime));
        dispatch(clearExecutiveReportLink())
    }catch (error){
        if (error.response.data) {
            dispatch(getExecutiveReportLinkError(error.response.data));
            dispatch(notification("Reportes", error.response.data.message, true));
        }
        dispatch(getExecutiveReportLinkError({message: "Error, intente más tarde"}));
    }
};

export const clearExecutiveReportLink = () => dispatch => {
    dispatch(getPurchasesBookLinkSuccess(null));
};

export const downloadFile = (link, name, type) => async dispatch => {

    try {
        const result = await ReportsController.downloadFile(link);
        saveAs(new Blob([result]),name, {type: type});
    } catch (error) {
        console.log(error);
        dispatch(notification('Reportes',"Error al descargar el reporte, intente más tarde.", true))
    }

};

export const downloadInvoicePdfFile = (invoiceId, name, type) => async dispatch => {

    try {
        const result = await ReportsController.downloadInvoicePdfFile(invoiceId);
        saveAs(new Blob([result]),name, {type: type});
        // dispatch(downloadInvoiceSuccess());
        dispatch(downloadInvoiceSuccess({}));
    } catch (error) {
        console.log(error);
        dispatch(downloadInvoiceFailure(error.response ? error.response.data : "Error en la conexión"));
        dispatch(notification('Reportes',"Error al descargar el reporte, intente más tarde.", true))
    }

};