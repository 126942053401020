import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from 'react-bootstrap-button-loader';
import {Link} from 'react-router-dom';
import {singleErrorSelector} from "../../selectors/error.selector";
import {loadingSelector} from "../../selectors/loading.selector";
import {actionTypes, changePassword, recoverToken} from "../../actions/user.actions";
import LoginContainer from '../Login/LoginContainer';
import logo from '../Login/Logo.png';
import '../Login/login.css'

class PasswordChange extends Component {

    state = {
        password: '',      
        password_confirmation: '',
        token: ''
    };
    
    componentDidMount () {
        const {token} = this.props.match.params;
        this.props.recoverToken(token);  
        this.setState({token: token})      
    }

    handleChange = (event) => {
        const {name, value} = event.target;                          
        this.setState({[name]: value});
    };

    changePassword = (event, token, password, password_confirmation) => {
        event.preventDefault();
        event.stopPropagation();            
        if(password === password_confirmation){
            this.props.changePassword(token, password, password_confirmation);
        } else {
            this.props.changePasswordErrors.data = {
                password: "contraseña no coincide con confirmación de contraseña", 
                password_confirmation: "confirmación de contraseña no coincide con contraseña"
            };
            this.forceUpdate();
        }
    };

    render() {        
        const {isChangePasswordLoading, changePasswordErrors, isRecoverTokenLoading} = this.props;
        const {token, password, password_confirmation} = this.state;                                      
        const errors = (changePasswordErrors.data) ? changePasswordErrors.data : {password:"", password_confirmation: ""};

        return (
            <LoginContainer>
                <div>
                    <img src={logo} className={"login-logo"} alt="logo"/>
                </div>
                <form autoComplete={"off"}  onSubmit={(event) => this.changePassword(event, token, password, password_confirmation)} className={'login-form'}>
                    <div className={"login-form-title"}>
                        <h1>Bienvenido</h1>
                        <h5>Ingresa tu nueva contraseña</h5>
                    </div>
                    <div className={'input-wrapper'} style={{display: !isRecoverTokenLoading? 'none': 'flex'}}>
                        <i className={'fa fa-spinner fa-spin'} style={{fontSize: 40, textAlign:'center', width:'100%', color: '#3498DB'}}/>
                    </div>
                    <div className={'input-wrapper'} style={{display: isRecoverTokenLoading? 'none': 'flex'}}>
                        <input      
                            autoFocus                    
                            type="password"
                            name={"password"}
                            id={"password"}
                            value={password}
                            onChange={this.handleChange}
                            className={"input form-control " + (errors.password ? " has-error" : "")}
                            placeholder={"Contraseña"}
                            required={""}
                        />
                        <span className="input-focus"/>
                        <span className={`input-symbol ${errors.password? 'has-error':''}`}>
							<i className="fas fa-at" aria-hidden="true"/>
						</span>
                        {errors.password ? <div className="input-error">{errors.password}</div> : <div/>}
                    </div>   
                    <div className={'input-wrapper'} style={{display: isRecoverTokenLoading? 'none': 'flex'}}>
                        <input                                                   
                            type="password"
                            name={"password_confirmation"}
                            id={"password_confirmation"}
                            value={password_confirmation}
                            onChange={this.handleChange}
                            className={"input form-control " + (errors.password ? " has-error" : "")}
                            placeholder={"Confirmación de Contraseña"}
                            required={""}
                        />
                        <span className="input-focus"/>
                        <span className={`input-symbol ${errors.password_confirmation? 'has-error':''}`}>
							<i className="fas fa-at" aria-hidden="true"/>
						</span>
                        {errors.password_confirmation ? <div className="input-error">{errors.password_confirmation}</div> : <div/>}
                    </div>   
                    <div className={'button-container'} style={{display: isRecoverTokenLoading? 'none': 'flex'}}>
                        <Button type="submit" className={"login-button"} loading={isChangePasswordLoading} >
                            CAMBIAR CONTRASEÑA
                        </Button>         
                        <Link to={`${process.env.PUBLIC_URL}/login`} className={"txt2"} style={{marginTop: 25, cursor:'pointer'}}>
                            <span className={'blue'}>VOLVER A INICIO DE SESION</span>
                        </Link>
                    </div>                        
                </form>
            </LoginContainer>
        );

    }

}


const mapStateToProps = state => ({
    isChangePasswordLoading: loadingSelector([actionTypes.CHANGE_PASSWORD])(state),   
    changePasswordErrors: singleErrorSelector([actionTypes.CHANGE_PASSWORD])(state),
    isRecoverTokenLoading: loadingSelector([actionTypes.RECOVER_TOKEN])(state),
});

const mapDispatchToProps = dispatch => ({
    changePassword: (token, password, password_confirmation) => dispatch(changePassword(token, password, password_confirmation)),
    recoverToken: (token) => dispatch(recoverToken(token))
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChange);
