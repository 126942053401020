import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Col, Panel, PanelGroup, Row, Table} from "react-bootstrap";
import Select from "react-select";
import isEmpty from "lodash/isEmpty";
import {Decimal} from "decimal.js";
import Button from "react-bootstrap-button-loader";
import {
    selectAvailableCompanies,
    selectAvailableWarehouse,
    selectAvailableCash,
    selectActiveCompany, selectActiveWarehouse, selectActiveCash, isLoadingCashTransfer
} from "../../selectors/sales.selector";
import {
    setUserCompany,
    setUserWarehouse,
    setUserCash,
    getAvailablePrice,
    getWarehouse,
    getCash,
    getCompanies,
    generateInvoice, cashTransfer as cashTransferAction
} from "../../actions/product.actions";
import {history} from "../../App";
import {selectShowToolbar} from "../../selectors/dashboard.selector";
import {showToolbar} from "../../actions/dashboard.actions";

function CashTransfer()
{
    const dispatch = useDispatch();
    const isToolBarSelected = useSelector(selectShowToolbar);
    const availableCash = useSelector(state => selectAvailableCash(state));
    const [selectedCashIssuer, setSelectedCashIssuer] = useState({label: '', value: '', name: ''});
    const [selectedCashReceive, setSelectedCashReceive] = useState({label: '', value: '', name: ''});
    const activeCompany = useSelector(selectActiveCompany);
    const activeWarehouse = useSelector(selectActiveWarehouse);
    const activeCash = useSelector(selectActiveCash);
    const isLoadingCashTransferRequest = useSelector(isLoadingCashTransfer);
    const [cashAmount, setCashAmount] = useState(0);
    const [description, setDescription] = useState("");

    const handleCashChangeIssuer = selectedOption => {
        setSelectedCashIssuer(selectedOption);
    };

    const handleCashChangeReceive = selectedOption => {
        setSelectedCashReceive(selectedOption);
    };

    useEffect(() => {
        if(!isToolBarSelected)
        {
            dispatch(showToolbar(true));
        }
        if(isEmpty(activeCompany) || isEmpty(activeWarehouse) || isEmpty(activeCash))
        {
            history.replace(`${process.env.PUBLIC_URL}/config`);
        }
    }, []);


    const transferAmount = () => {
        const request = {
            from: "2",
            to: "2",
            issuerCash: selectedCashIssuer.value,
            issuerBank: null,
            receivingCash: selectedCashReceive.value,
            receivingBank: null,
            docNumber: "",
            date: Date.now(),
            description: description,
            exchangeRate: null,
            details:[
                {
                    currency: 0,
                    amount: cashAmount,
                    document: "",
                    externalReference: selectedCashReceive.value,
                    externalReferenceType: 1,
                }
            ]
        };
        dispatch(cashTransferAction(request));
        setSelectedCashIssuer({label: '', value: '', name: ''});
        setSelectedCashReceive({label: '', value: '', name: ''});
        setCashAmount(0);
        setDescription(0);
    };

    return  (
        <div>
            <Row>
                <Col xs={12} md={12} className={'padding-0'}>
                    <div className={'panel'}>
                        <h3 className={'blue montserrat f500'}> <b>Transferencia entre cajas</b> </h3>
                        <Row>
                            <Col xs={12} md={12}>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <div style={{textAlign: 'left'}}>
                                            <h2 className={'blue montserrat f500 fs-16'}><b>Seleccionar datos</b></h2>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={4}>
                                        <div className={'cv-input-wrapper'}>
                                            <div className={'label'}>
                                                Caja de: <span className={'required'}>*</span>
                                            </div>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name="color"
                                                isClearable={false}
                                                isSearchable={true}
                                                options={availableCash.map( company => ({...company, label: company.name, value: company.id}))}
                                                value={selectedCashIssuer}
                                                onChange={handleCashChangeIssuer}
                                                placeholder={"Buscar Caja"}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <div className={'cv-input-wrapper'}>
                                            <div className={'label'}>
                                                Caja a: <span className={'required'}>*</span>
                                            </div>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name="color"
                                                isClearable={false}
                                                isSearchable={true}
                                                options={availableCash.map( company => ({...company, label: company.name, value: company.id}))}
                                                value={selectedCashReceive}
                                                onChange={handleCashChangeReceive}
                                                placeholder={"Buscar Caja"}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <div className={'cv-input-wrapper'}>
                                            <div className={'label'}>
                                                Efectivo: <span className={'required'}>*</span>
                                            </div>
                                            <input id={"Efectivo"} type="number" className={'cv-input'} placeholder={"Efectivo"} name={"Efectivo"} value={cashAmount} onChange={ (value)=> setCashAmount(value.target.value) }/>
                                        </div>
                                    </Col>

                                </Row>

                                <Row>

                                    <Col xs={12} md={12}>
                                        <div className={'cv-input-wrapper'}>
                                            <div className={'label'}>
                                                Descripcion: <span className={'required'}>*</span>
                                            </div>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" name='description' value={description} onChange={(value) => setDescription(value.target.value)}/>
                                        </div>
                                    </Col>

                                </Row>





                                <Row>
                                    <Col xs={12} md={12}>
                                        <div style={{textAlign: 'right'}}>
                                            {
                                                isLoadingCashTransferRequest ? <div className={'input-wrapper'} style={{display: 'flex'}}>
                                                    <i className={'fa fa-spinner fa-spin'} style={{fontSize: 40, textAlign:'center', width:'100%', color: '#3498DB'}}/>
                                                </div> :
                                                    <Button className={'custom-button-green'} onClick={transferAmount}>
                                                        Guardar Informacion <i className={'fas fa-chevron-right'}/>
                                                    </Button>
                                            }

                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>



        </div>
    );
}

export default CashTransfer;