import {actionTypes} from "../actions/user.actions";

const initialState = {
    data: null,
    extra: {nit: '', phone: ''},
    taxRegimeConfigured: null,
    taxRegime: 0,
    validatedModal:false,
    countries:[],
    acceptedTerms: false
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COUNTRY_REQUEST:
            return {
                ...state,
            };
        case actionTypes.GET_COUNTRY_SUCCESS:
            return {
                ...state,
                countries: action.data
            };
        case actionTypes.LOGIN_REQUEST:
            return {
                ...state,
            };
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                data: action.user,
            };
        case actionTypes.REGISTER_REQUEST:
            return {
                ...state,
            };
        case actionTypes.REGISTER_SUCCESS:
            return {
                ...state,
                data: action.user,
            };        
        case actionTypes.REFRESH_REQUEST:
            return {
                ...state,
            };
        case actionTypes.REFRESH_SUCCESS:
            return {
                ...state,
                data: action.user,
            };
        case actionTypes.RECOVER_PASSWORD_REQUEST:
            return {
                ...state,
            };
        case actionTypes.RECOVER_PASSWORD_SUCCESS:
            return {
                ...state,
            };            
        case actionTypes.CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
            };
        case actionTypes.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
            };
        case actionTypes.RESEND_EMAIL_REQUEST:
            return {
                ...state,
            };
        case actionTypes.RESEND_EMAIL_SUCCESS:
            return {
                ...state,
            };
        case actionTypes.ACCEPTED_TERMS_REQUEST:
            return {
                ...state,
            };
        case actionTypes.ACCEPTED_TERMS_SUCCESS:
            return {
                ...state,
                acceptedTerms: action.value
            };
        case actionTypes.ACCEPT_TERMS_REQUEST:
            return {
                ...state,
            };
        case actionTypes.ACCEPT_TERMS_SUCCESS:
            return {
                ...state,
            };
        case actionTypes.GET_EXTRA_DATA_REQUEST:
            return {
                ...state,
            };
        case actionTypes.GET_EXTRA_DATA_SUCCESS:
            return {
                ...state,
                extra: action.data,
            };
        case actionTypes.SAVE_EXTRA_DATA_REQUEST:
            return {
                ...state,
            };
        case actionTypes.SAVE_EXTRA_DATA_SUCCESS:
            return {
                ...state,
            };
        case actionTypes.RECOVER_TOKEN_REQUEST:
            return {
                ...state,
            };
        case actionTypes.RECOVER_TOKEN_SUCCESS:
            return {
                ...state,
            };
        case actionTypes.VALIDATE_TOKEN_REQUEST:
            return {
                ...state,
            };
        case actionTypes.VALIDATE_TOKEN_SUCCESS:
            return {
                ...state,
                data: action.user,
            };
        case actionTypes.LOGOUT_REQUEST:
            return {
                ...state,
            };
        case actionTypes.LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
};

export default userReducer;