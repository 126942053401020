export const selectUser = (state) => state.user.data;
export const selectToken = (state) => state.user.data ? 'Bearer ' + state.user.data.token : null;
export const selectUserName = (state) => state.user.data ? (state.user.data.firstName + ' ' + state.user.data.lastName) : '';
export const selectUserFirstName = (state) => state.user.data ? state.user.data.firstName : '';
export const selectUserLastName = (state) => state.user.data ? state.user.data.lastName : '';
export const selectUserAcceptedTerms = (state) => state.user.acceptedTerms;
export const selectUserImage = (state) => state.user.data ? state.user.data.image : '';
export const selectExtra = (state) => state.user.extra ? state.user.extra : {nit: '', phone: ''};
export const selectNit = (state) => selectExtra(state).nit;
export const selectPhone = (state) => selectExtra(state).phone;

export const selectCountries = (state) => state.user.countries? state.user.countries.map(item => { return {value: item.id, label:item.name}} ): [];