import IncomesController from '../services/incomes.service'
import {error as errorNotification, success as successNotification} from 'react-notification-system-redux';

export const actionTypes = {
    SAVE_INCOME: 'SAVE_INCOME',
    SAVE_INCOME_REQUEST: 'SAVE_INCOME_REQUEST',
    SAVE_INCOME_SUCCESS: 'SAVE_INCOME_SUCCESS',
    SAVE_INCOME_ERROR: 'SAVE_INCOME_ERROR',
};

const saveIncomeRequest = () => ({type: actionTypes.SAVE_INCOME_REQUEST});
const saveIncomeSuccess = value => ({type: actionTypes.SAVE_INCOME_SUCCESS, value});
const saveIncomeError   = error => ({type: actionTypes.SAVE_INCOME_ERROR, error,});


const notification = (title, message, error) => dispatch =>{
    if(!error) {
        dispatch( successNotification({title, message}));
    } else {
        dispatch( errorNotification({title, message}));
    }
    return true;
};

export const saveIncome = (nit, name, series, number, date, total, serviceSale, invoice) => async (dispatch) => {
    dispatch(saveIncomeRequest());
    try {
        await IncomesController.saveIncome(nit, name, series, number, date, total, serviceSale, invoice);
        dispatch(saveIncomeSuccess(true));
        dispatch(notification("Ingresos", "Documento guardado exitosamente."));
    } catch (error) {
        console.log(error);
        if(error.response.data){
            dispatch(saveIncomeError(error.response.data));
            dispatch(notification("Ingresos", error.response.data.message, true));
        }
        dispatch(saveIncomeError({message: "Error, intente más tarde"}));
    }
};

export const clearSaveIncomeResult = () => dispatch => {
    dispatch( saveIncomeSuccess(false));
};