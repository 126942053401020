import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import './CustomDatePicker.css'
import 'react-day-picker/lib/style.css';

export default class CustomDatePicker extends DayPickerInput {


    validateDate = () => {
        const {parseDate, dayPickerProps, format} = this.props;
        if (this.state.value && this.state.value.trim() !== '') {
            const day = parseDate(this.state.value, format, dayPickerProps.locale);
            if (!day) {
                this.setState({error: 'La fecha ingresada no es válida'});
            } else {
                if (day > this.props.max) {
                    this.setState({error: 'No puedes ingresar una fecha a futuro'});
                } else {
                    this.setState({error: ''});
                }
            }
        } else {
            this.setState({error: ''});
        }
    };

    render() {
        const Input = this.props.component;
        const {inputProps, error} = this.props;
        return (
            <div className={'input-wrapper'} style={{zIndex: 10}}>

                <Input className={"input form-control " + ( (error || this.state.error) ? " has-error" : "")}
                       style={this.props.inputStyle}
                       ref={el => (this.input = el)}
                       placeholder={this.props.placeholder}
                       {...inputProps}
                       value={this.state.typedValue || this.state.value}
                       onChange={this.handleInputChange}
                       onFocus={this.handleInputFocus}
                       onBlur={() => { this.handleInputBlur();  this.validateDate()}}
                       onKeyDown={this.handleInputKeyDown}
                       onKeyUp={this.handleInputKeyUp}
                       onClick={!inputProps.disabled ? this.handleInputClick : undefined}
                />
                {
                    this.props.focus && <span className="input-focus"/>
                }
                {
                    this.props.icon &&
                    <span className={`input-symbol ${(error || this.state.error) ? 'has-error' : ''}`}>
                        <i className="fas fa-calendar-alt" aria-hidden="true"/>
                    </span>
                }
                {(error || this.state.error) ? <div className="input-error">{error || this.state.error}</div> : <div/>}
                {this.state.showOverlay && this.renderOverlay()}
            </div>
        );
    }
}