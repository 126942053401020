import {actionTypes} from '../actions/reports.actions'


const initialState = {
    purchasesBookLink: null,
    salesBookLink: null,
    executiveReportLink: null,
};

const ReportsReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GET_PURCHASES_BOOK_LINK_REQUEST:
            return {
                ...state,
            };
        case actionTypes.GET_PURCHASES_BOOK_LINK_SUCCESS:
            return {
                ...state,
                purchasesBookLink: action.link
            };
        case actionTypes.GET_SALES_BOOK_LINK_REQUEST:
            return {
                ...state,
            };
        case actionTypes.GET_SALES_BOOK_LINK_SUCCESS:
            return {
                ...state,
                salesBookLink: action.link
            };
        case actionTypes.GET_EXECUTIVE_REPORT_LINK_REQUEST:
            return {
                ...state,
            };
        case actionTypes.GET_EXECUTIVE_REPORT_LINK_SUCCESS:
            return {
                ...state,
                salesBookLink: action.link
            };
        default:
            return state? state: initialState;
    }

};

export default ReportsReducer;