import * as React from 'react';

class MenuToogle extends React.Component {



    render() {
        return (
            <div className={'toogle'} onClick={this.props.onToogle}>
                <a style={{fontSize:24}}>
                    {/*<i className={"fas fa-sign-out-alt"} style={{color: '#9b9b9b'}}/>*/}
                    <i className={"fas fa-ellipsis-v"} style={{color: '#9b9b9b'}}/>
                </a>
            </div>
        );

    }

}

export default MenuToogle;