import React, {Component} from 'react';
import {Router} from "react-router-dom";
import {connect} from 'react-redux';
import IdleTimer from 'react-idle-timer';
import createHistory from 'history/createBrowserHistory';
import {SideBar} from './components/SideBar/SideBar';
import {ToolBar} from './components/ToolBar/ToolBar';
import Layout from './components/Layout/Layout';
import Content from './content/Content'
import {logout} from "./actions/user.actions";
import {MENU_OPTIONS} from './settings/menu.options';
import {APP_IDLE_TIME, APP_SITE, APP_TITLE} from './settings/app.settings';
import logo from './logo.png';
import { Footer } from './components/Footer/Footer';


export const history = createHistory();


class App extends Component {

    logout = () => this.props.logout();

    render() {
        const {notifications} = this.props;

        return (

            <IdleTimer
                ref={ref => {
                    this.idleTimer = ref
                }}
                onIdle={this.logout}
                timeout={APP_IDLE_TIME}
            >
                <Router history={history} basename={'/app'}>
                    <Layout notifications={notifications}>
                        <ToolBar/>
                        <Content/>
                        <Footer/>
                    </Layout>
                </Router>
            </IdleTimer>
        );
    }
}

const mapStateToProps = state => ({
    notifications: state.notifications
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
});

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export {connectedApp as App};