import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Col, Panel, PanelGroup, Row, Table} from "react-bootstrap";
import Select from "react-select";
import isEmpty from "lodash/isEmpty";
import {Decimal} from "decimal.js";
import Button from "react-bootstrap-button-loader";
import {
    selectAvailableCompanies,
    selectAvailableWarehouse,
    selectAvailableCash,
    selectInvoiceList,
    selectActiveCompany, selectActiveWarehouse, selectActiveCash, isLoadingDownloadInvoice
} from "../../selectors/sales.selector";
import {setUserCompany, setUserWarehouse, setUserCash, getAvailablePrice, getWarehouse, getCash, getCompanies, getInvoiceList, downloadInvoice} from "../../actions/product.actions";
import {history} from "../../App";
import {showToolbar} from "../../actions/dashboard.actions";
import {selectShowToolbar} from "../../selectors/dashboard.selector";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";
import MomentLocaleUtils, {formatDate, parseDate} from "react-day-picker/moment";
import DatePicker from "react-datepicker/es";
import "react-datepicker/dist/react-datepicker.css"
function Invoices()
{
    const dispatch = useDispatch();
    const isToolBarSelected = useSelector(selectShowToolbar);
    const invoiceList = useSelector(state => selectInvoiceList(state));
    const activeCompany = useSelector(selectActiveCompany);
    const activeWarehouse = useSelector(selectActiveWarehouse);
    const activeCash = useSelector(selectActiveCash);
    const isLoadingDownload = useSelector(isLoadingDownloadInvoice);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
        if(!isToolBarSelected)
        {
            dispatch(showToolbar(true));
        }
        if(isEmpty(activeCompany) || isEmpty(activeWarehouse) || isEmpty(activeCash))
        {
            history.replace(`${process.env.PUBLIC_URL}/config`);
        }
        else{
            dispatch(getInvoiceList(Date.now(), Date.now()));
        }


    }, []);

    return  (
        <div>
            <Row>
                <Col xs={12} md={12} className={'padding-0'}>
                    <div className={'panel'}>
                        <h3 className={'blue montserrat f500'}> <b>Listado de facturas</b> </h3>


                        <Row>
                            <Col xs={12} md={2}>
                                <div className={'cv-input-wrapper'}>
                                    <div className={'label'}>
                                        Fecha de Inicio: <span className={'required'}>*</span>
                                    </div>
                                    <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                                </div>
                            </Col>

                            <Col xs={12} md={2}>
                                <div className={'cv-input-wrapper'}>
                                    <div className={'label'}>
                                        Fecha Final: <span className={'required'}>*</span>
                                    </div>
                                    <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
                                </div>
                            </Col>

                            <Col xs={12} md={8}>
                                <div style={{textAlign: 'left'}}>
                                    <Button className={'custom-button-green modal-button-footer'} onClick={ () => dispatch(getInvoiceList(startDate.getTime(), endDate.getTime()))}> <i className={'fas fa-chevron-right'}/> Buscar </Button>
                                </div>
                            </Col>
                            <br/>
                            <br/>
                        </Row>
                        <br/>

                        <Row>
                            <Col xs={12} md={12}>
                                <Table responsive striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th className={'col-md-2'}>Serie</th>
                                        <th className={'col-md-2'}>Numero</th>
                                        <th className={'col-md-1'}>Fecha</th>
                                        <th className={'col-md-2'}>NIT</th>
                                        <th className={'col-md-2'}>Nombre</th>
                                        <th className={'col-md-2'}>Total</th>
                                        <th className={'col-md-1'}>Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        invoiceList.map((item, index) => {
                                            const {series, number, date, nit, name, totalCost, totalTaxes} = item;
                                            const invoiceDate = new Date(date);
                                            let total = new Decimal(totalCost);
                                            let taxes = new Decimal(totalTaxes);
                                            const price = total.plus(taxes).toFixed(2, Decimal.ROUND_HALF_EVEN);
                                            return (
                                                <tr key={number}>
                                                    <td>{series}</td>
                                                    <td>{number}</td>
                                                    <td>{invoiceDate.getDate() + "/" + (invoiceDate.getMonth() + 1) + "/" + invoiceDate.getFullYear()}</td>
                                                    <td>{nit}</td>
                                                    <td>{name}</td>
                                                    <td>Q. {price}</td>
                                                    <td>
                                                        {isLoadingDownload ?
                                                            <div className={'input-wrapper'} style={{display: 'flex'}}>
                                                                <i className={'fa fa-spinner fa-spin '} style={{fontSize: 40, textAlign:'center', width:'100%', color: '#3498DB'}}/>
                                                            </div> :
                                                            <a style={{cursor:'pointer',color: '#43425D'}} onClick={(e) => { dispatch(downloadInvoice(item.id))  }}>
                                                                <Col xs={12} md={12}>
                                                                    <i  className={"fas fa-download"}></i>
                                                                </Col>
                                                            </a>
                                                        }

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>


                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>



        </div>
    );
}

export default Invoices;