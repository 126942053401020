import React, {Component} from 'react'
import CustomModal from "../../components/CustomModal/CustomModal";
import {Button} from "react-bootstrap";
import {history} from "../../App";

export default class NotFoundComponent extends Component {

    render() {
        return (
            <CustomModal type={'warning'}
                         show={true} title={'¡uh-oh!'}
                         footer=
                             {
                                 <Button bsStyle={'link'} onClick={history.replace(`${process.env.PUBLIC_URL}/`)}
                                         style={{width: '100%', textAlign: 'center', color: 'white', fontWeight: 700, fontSize: 16}}>
                                     Regresar
                                 </Button>
                             }
            >
                <div style={{textAlign: 'center', width: '100%', paddingBottom: 20}}>Lo sentimos, la pagina que buscas no existe</div>
            </CustomModal>
        );
    }
}