import {applyMiddleware, createStore, compose} from 'redux';
import thunk from 'redux-thunk';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer} from './root.reducer';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['loading','error','dashboard', 'charts', 'declarations','payments','nit', 'incomes','expenses','reports', 'salesReducer', 'notifications'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const rootStore = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk)),
);

export const store = rootStore;
export const persistor = persistStore(store);

export const persist = callback => persistStore(rootStore, null, callback);
