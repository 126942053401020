export const selectAvailableProducts = (state) => state.salesReducer.products ? state.salesReducer.products : [];

export const selectAvailableProductPrice = (state) => state.salesReducer.productPrices;

export const selectAvailableClients = (state) => state.salesReducer.clients ? state.salesReducer.clients : [];
export const isLoadingAvailableClients = (state) => state.salesReducer.isLoadingClient;
export const isLoadingCreateClient = (state) => state.salesReducer.isLoadingCreateClient;
export const isLoadingProduct = (state) => state.salesReducer.isLoadingProduct;
export const isLoadingProductPrices = (state) => state.salesReducer.isLoadingProductPrices;
export const isLoadingGenerateInvoice = (state) => state.salesReducer.isLoadingGenerateInvoice;
export const isLoadingDownloadInvoice = (state) => state.salesReducer.isLoadingDownloadInvoice;
export const isLoadingCashTransfer = (state) => state.salesReducer.isLoadingCashTransfer;
export const isLoadingAvailableCompanies = (state) => state.salesReducer.isLoadingAvailableCompanies;
export const isLoadingAvailableWarehouse = (state) => state.salesReducer.isLoadingAvailableWarehouse;
export const isLoadingAvailableCash = (state) => state.salesReducer.isLoadingAvailableCash;


export const selectAvailableCompanies = (state) => state.salesReducer.availableCompanies ? state.salesReducer.availableCompanies : [];
export const selectAvailableWarehouse = (state) => state.salesReducer.availableWarehouses ? state.salesReducer.availableWarehouses : [];
export const selectAvailableCash = (state) => state.salesReducer.availableCash ? state.salesReducer.availableCash : [];

export const selectActiveCompany = (state) => state.salesReducer.company ? state.salesReducer.company : {};
export const selectActiveWarehouse = (state) => state.salesReducer.warehouse ? state.salesReducer.warehouse : {};
export const selectActiveCash = (state) => state.salesReducer.cash ? state.salesReducer.cash : {};
export const selectResetInvoice = (state) => state.salesReducer.resetInvoice ? state.salesReducer.resetInvoice : false;
export const selectInvoiceList = (state) => state.salesReducer.invoices;
export const selectCreatedInvoice = (state) => state.salesReducer.invoice;

