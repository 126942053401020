import {actionTypes} from '../actions/payments.actions'


const initialState = {
    status: {hiringDate: new Date(), cutOffDate: new Date() }
};

const PaymentsReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GET_SERVICE_STATUS_REQUEST:
            return {
                ...state,
            };
        case actionTypes.GET_SERVICE_STATUS_SUCCESS:
            return {
                ...state,
                status: action.data,
            };
        default:
            return state? state: initialState;
    }

};

export default PaymentsReducer;