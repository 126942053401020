import React, {Component} from 'react';
import {connect} from 'react-redux';
import {selectCountries, selectToken} from "../../selectors/user.selector";
import {singleErrorSelector} from "../../selectors/error.selector";
import {loadingSelector} from "../../selectors/loading.selector";
import {actionTypes, getCountries, register} from "../../actions/user.actions";
import Button from 'react-bootstrap-button-loader';
import Select from "react-select";
import '../Login/login.css';

class RegistrationForm extends Component {


    componentDidMount() {
        this.props.getCountries();
    }

    state = {
        email: '',
        password: '',
        country: null,
    };

    register = (event, country, email, password) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.register(country, email, password);
    };

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    };

    findByNit = () => {
        const {nit} = this.state;
        if (nit) this.props.findByNit(nit);
    };

    onCountryChange = (country) => {
        this.setState({country: country});
    };


    render() {

        const {isRegisterLoading, registerErrors, countries} = this.props;
        const errors = (registerErrors.data) ? registerErrors.data : {country: "", email: "", password: ""};
        const {country, email, password} = this.state;

        const whiteSelectStyle = {  control: (provided, state) => ({...provided, border: 'none', borderBottom: '1px solid #555555', fontFamily: 'Montserrat, sans-serif', fontWeight: 500, paddingLeft: 25}), };
        return (
            <form autoComplete={"off"} onSubmit={(event) => this.register(event, country, email, password)} className={'login-form'}>
                <div className={"login-form-title"}>
                    <h3 className={"blue f400"}>Bienvenido</h3>
                    <h5 className={"open-sans"}>Ingresa tus datos para crear una cuenta</h5>
                </div>

                <div className={'input-wrapper'}>
                    <Select
                        styles={whiteSelectStyle}
                        value={country}
                        onChange={this.onCountryChange}
                        options={countries}
                        placeholder={'País'}
                        menuPortalTarget={document.querySelector('body')}
                    />
                    <span className="input-focus"/>
                    <span className={`input-symbol ${errors.mail ? 'has-error' : ''}`}>
							<i className="fas fa-globe-americas" aria-hidden="true"/>
						</span>
                    {errors.country ? <div className="input-error">{errors.country}</div> : <div/>}
                </div>
                <div className={'input-wrapper'}>
                    <input
                        type="email"
                        name={"email"}
                        id={"email"}
                        value={email}
                        onChange={this.handleChange}
                        className={"input form-control " + (errors.mail ? " has-error" : "")}
                        placeholder={"Correo Electrónico"}
                        required={""}
                    />
                    <span className="input-focus"/>
                    <span className={`input-symbol ${errors.mail ? 'has-error' : ''}`}>
							<i className="fas fa-at" aria-hidden="true"/>
						</span>
                    {errors.mail ? <div className="input-error">{errors.mail}</div> : <div/>}
                </div>
                <div className={'input-wrapper'}>
                    <input
                        type={"password"}
                        name={"password"}
                        id={"password"}
                        value={password}
                        onChange={this.handleChange}
                        className={"input form-control " + (errors.pass ? " has-error" : "")}
                        placeholder={"Contraseña"}
                        required={""}
                    />
                    <span className="input-focus"/>
                    <span className={`input-symbol ${errors.pass ? 'has-error' : ''}`}>
							<i className="fas fa-key" aria-hidden="true"/>
						</span>
                    {errors.pass ? <div className="input-error">{errors.pass}</div> : <div/>}
                </div>
                <div className={'button-container'}>
                    <Button type="submit" className={"login-button"} loading={isRegisterLoading}>
                        REGISTRARME
                    </Button>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => ({
    isRegisterLoading: loadingSelector([actionTypes.REGISTER])(state),
    registerErrors: singleErrorSelector([actionTypes.REGISTER])(state),

    countries: selectCountries(state),
    token: selectToken(state),
});

const mapDispatchToProps = dispatch => ({
    register: (country, email, password) => dispatch(register(country, email, password)),
    getCountries: () => dispatch(getCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
