export const actionTypes = {
    MENU_SHOW: 'MENU_SHOW',
    MENU_EXPAND: 'MENU_EXPAND',
    MENU_SELECTED: 'MENU_SELECTED',
    SHOW_TOOLBAR: 'SHOW_TOOLBAR',

    MONTH_SELECTED: 'MONTH_SELECTED',
};

const menuShow = (val) => ({type: actionTypes.MENU_SHOW, val});
const toolbarShow = (val) => ({type:actionTypes.SHOW_TOOLBAR, val});
const menuExpand = (val) => ({type: actionTypes.MENU_EXPAND, val});
const menuSelected = (val) => ({type: actionTypes.MENU_SELECTED, val});
const monthSelected= (val) => ({type: actionTypes.MONTH_SELECTED, val});

export const showMenu = (val) => dispatch => {
    dispatch(menuShow(val));
};

export const showToolbar = (val) => dispatch => {
    dispatch(toolbarShow(val));
};

export const expandMenu = (val) => dispatch => {
    dispatch(menuExpand(val));
};

export const selectMenu = (val) => dispatch => {
    dispatch(menuSelected(val));
    dispatch(menuExpand(true))
};

export const selectMonthFromSelect = (val) => dispatch => {
    dispatch(monthSelected(val));
};
