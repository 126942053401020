import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Col, Panel, PanelGroup, Row, Table} from "react-bootstrap";
import Select from "react-select";
import isEmpty from "lodash/isEmpty";
import {Decimal} from "decimal.js";
import Button from "react-bootstrap-button-loader";
import {selectAvailableCompanies, selectAvailableWarehouse, selectAvailableCash, isLoadingAvailableCompanies, isLoadingAvailableWarehouse, isLoadingAvailableCash} from "../../selectors/sales.selector";
import {setUserCompany, setUserWarehouse, setUserCash, getAvailablePrice, getWarehouse, getCash, getCompanies} from "../../actions/product.actions";
import {history} from "../../App";
import {selectShowToolbar} from "../../selectors/dashboard.selector";

function UserConfiguration()
{
    const dispatch = useDispatch();
    const isToolBarSelected = useSelector(selectShowToolbar);
    const companies = useSelector(state => selectAvailableCompanies(state));
    const availableWarehouse = useSelector(state => selectAvailableWarehouse(state));
    const availableCash = useSelector(state => selectAvailableCash(state));
    const isLoadingCompaniesRequest = useSelector(isLoadingAvailableCompanies);
    const isLoadingWarehouseRequest = useSelector(isLoadingAvailableWarehouse);
    const isLoadingCashRequest = useSelector(isLoadingAvailableCash);

    const [selectedCompany, setSelectedCompany] = useState({label: '', value: '', name: ''});
    const [selectedWarehouse, setSelectedWarehouse] = useState({label: '', value: '', name: ''});
    const [selectedCash, setSelectedCash] = useState({label: '', value: '', name: ''});
    const handleCompanyChange = selectedOption => {
        setSelectedCompany(selectedOption);
        dispatch(setUserCompany({...selectedOption, id: selectedOption.companyId}));
        dispatch(getWarehouse());
        dispatch(getCash());
    };
    const handleWarehouseChange = selectedOption => {
        setSelectedWarehouse(selectedOption);
    };
    const handleCashChange = selectedOption => {
        setSelectedCash(selectedOption);
    };

    useEffect(() => {
        if(isEmpty(companies))
        {
            dispatch(getCompanies());
        }
        if(isEmpty(companies))
        {
            dispatch(getCompanies());
        }
    }, []);


    const saveConfiguration = () => {
        dispatch(setUserCompany({...selectedCompany, id: selectedCompany.companyId}));
        dispatch(setUserWarehouse(selectedWarehouse));
        dispatch(setUserCash(selectedCash));
        history.replace(`${process.env.PUBLIC_URL}/`);
    };

    return  (
        <div>
            <Row>
                <Col xs={12} md={12} className={'padding-0'}>
                    <div className={'panel'}>
                        <h3 className={'blue montserrat f500'}> <b>Configuracion de punto de ventas</b> </h3>
                        <Row>
                            <Col xs={12} md={12}>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <div style={{textAlign: 'left'}}>
                                            <h2 className={'blue montserrat f500 fs-16'}><b>Seleccionar datos</b></h2>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={4}>
                                        <div className={'cv-input-wrapper'}>
                                            <div className={'label'}>
                                                Empresa: <span className={'required'}>*</span>
                                            </div>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name="company"
                                                isClearable={false}
                                                isSearchable={true}
                                                options={companies.map( company => ({...company, label: company.companyName, value: company.companyId}))}
                                                value={selectedCompany}
                                                onChange={handleCompanyChange}
                                                placeholder={"Buscar Empresa"}
                                                isLoading={isLoadingCompaniesRequest}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <div className={'cv-input-wrapper'}>
                                            <div className={'label'}>
                                                Bodega: <span className={'required'}>*</span>
                                            </div>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name="color"
                                                isClearable={false}
                                                isSearchable={true}
                                                options={availableWarehouse.map( company => ({...company, label: company.name, value: company.id}))}
                                                value={selectedWarehouse}
                                                onChange={handleWarehouseChange}
                                                placeholder={"Buscar Bodega"}
                                                isLoading={isLoadingWarehouseRequest}

                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <div className={'cv-input-wrapper'}>
                                            <div className={'label'}>
                                                Caja: <span className={'required'}>*</span>
                                            </div>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name="color"
                                                isClearable={false}
                                                isSearchable={true}
                                                options={availableCash.map( company => ({...company, label: company.name, value: company.id}))}
                                                value={selectedCash}
                                                onChange={handleCashChange}
                                                placeholder={"Buscar Caja"}
                                                isLoading={isLoadingCashRequest}
                                            />
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <div style={{textAlign: 'right'}}>
                                            <Button className={'custom-button-green'} onClick={saveConfiguration}>
                                                Guardar Informacion <i className={'fas fa-chevron-right'}/>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>



        </div>
    );
}

export default UserConfiguration;