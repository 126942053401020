import React, {Component} from 'react';
import {connect} from 'react-redux';
import {selectToken} from "../../selectors/user.selector";
import './SideBar.css';
import {selectMenuCollapsed} from "../../selectors/dashboard.selector";
import {withBreakpoints} from 'react-breakpoints'
import Menu from "./Menu";

class SideBar extends Component {

    render() {
        const {token, collapsed, breakpoints, currentBreakpoint} = this.props;

        const collapsedClass = collapsed? '': 'expand ';
        const showClass= breakpoints[currentBreakpoint] < breakpoints.tabletLandscape+1 && collapsed ? 'hideNav' : 'showNav';
        return (
            token !== null  &&
            <aside id="slide_panel" className={collapsedClass+showClass}>
                <div className="fixed_wrap">
                    <div className="nav_aside">
                        <ul className="nav_l1">
                            <Menu options={this.props.options}/>
                        </ul>
                    </div>
                </div>
            </aside>

        );
    }
}

const mapStateToProps = (state) => ({
    token: selectToken(state),
    collapsed: selectMenuCollapsed(state),
});

const connectedSideBar = withBreakpoints(connect(mapStateToProps)( SideBar) );
export {connectedSideBar as SideBar};





