import {$http} from './http';
import axios from 'axios';
import {API_URL} from '../settings/app.settings'

class DeclarationsController {

    getMonthlyForms = async (year) => {
        try {
            const result = await $http.get(`/erp/forms/tax/${year}`);
            return result.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    closeMonth = async (year, month) => {
        try {
            const result = await $http.post(`/erp/company/close?year=${year}&month=${month}`);
            return result.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    formPaid = async (form) => {
        try {
            const request = {formId: form.id, description:'Reporte de formulario pagado desde MiContaGT'};
            const result = await $http.put(`/erp/forms/tax/change/state?formId=${form.id}`, request);
            return result.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    getFormDocumentLink= async (form) => {
        try{
            const result = await  $http.get(`/erp/forms/tax/download?formId=${form.id}&link=true`);
            return result.data.data;

        } catch (error) {
            throw  error;
        }
    };

    downloadFile = async (link) =>{
        try{
            const result = await axios({ url: `${API_URL}/downloads/${link}`, method: 'GET', responseType: 'blob' });
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}

export default new DeclarationsController();