import React, {Component} from 'react';
import Notifications from 'react-notification-system-redux';
import './Layout.css'

class Layout extends Component {

    render() {
        return (
            <div className={"layout"}>
                <Notifications
                    notifications={this.props.notifications}
                />
                {this.props.children}
            </div>
        );

    }

}

export default Layout;
