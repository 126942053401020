import salesConstants from '../constants/sales.constants';
import productService from "../services/product.service";
import Notifications, {error as errorNotification, success as successNotification} from 'react-notification-system-redux';
import {$http} from "../services/http";
import {downloadFile, downloadInvoicePdfFile} from "./reports.actions";

const getAvailableProductsRequest = () => ({ type: salesConstants.GET_PRODUCTS_REQUEST });
const getAvailableProductsSuccess = availableProducts => ({ type: salesConstants.GET_PRODUCTS_SUCCESS, availableProducts });
const getAvailableProductsFailure = error => ({ type: salesConstants.GET_PRODUCTS_ERROR, error });

const getAvailableClientsRequest = () => ({ type: salesConstants.GET_CLIENTS_REQUEST });
const getAvailableClientsSuccess = clients => ({ type: salesConstants.GET_CLIENTS_SUCCESS, clients });
const getAvailableClientsFailure = error => ({ type: salesConstants.GET_CLIENTS_ERROR, error });

const getInvoiceListRequest = () => ({ type: salesConstants.GET_INVOICES_REQUEST });
const getInvoiceListSuccess = invoices => ({ type: salesConstants.GET_INVOICES_SUCCESS, invoices });
const getInvoiceListFailure = error => ({ type: salesConstants.GET_INVOICES_ERROR, error });

const getAvailableProductsPriceRequest = () => ({ type: salesConstants.GET_PRODUCTS_PRICE_REQUEST });
const getAvailableProductsPriceSuccess = availablePrice => ({ type: salesConstants.GET_PRODUCTS_PRICE_SUCCESS, availablePrice });
const getAvailableProductsPriceFailure = error => ({ type: salesConstants.GET_PRODUCTS_PRICE_ERROR, error });
const clearProductsPrice = () => ({ type: salesConstants.CLEAR_PRODUCTS_PRICE });

const generateInvoiceRequest = () => ({ type: salesConstants.GENERATE_INVOICE_REQUEST });
const generateInvoiceSuccess = invoice => ({ type: salesConstants.GENERATE_INVOICE_SUCCESS, invoice });
const generateInvoiceFailure = error => ({ type: salesConstants.GENERATE_INVOICE_ERROR, error });

const downloadInvoiceRequest = () => ({ type: salesConstants.DOWNLOAD_INVOICE_REQUEST });
const downloadInvoiceSuccess = invoice => ({ type: salesConstants.DOWNLOAD_INVOICE_SUCCESS, invoice });
const downloadInvoiceFailure = error => ({ type: salesConstants.DOWNLOAD_INVOICE_ERROR, error });

const cashTransferRequest = () => ({ type: salesConstants.CASH_TRANSFER_REQUEST });
const cashTransferSuccess = invoice => ({ type: salesConstants.CASH_TRANSFER_SUCCESS, invoice });
const cashTransferFailure = error => ({ type: salesConstants.CASH_TRANSFER_ERROR, error });

const createClientRequest = () => ({ type: salesConstants.CREATE_CLIENT_REQUEST });
const createClientSuccess = client => ({ type: salesConstants.CREATE_CLIENT_SUCCESS, client });
const createClientFailure = error => ({ type: salesConstants.CREATE_CLIENT_ERROR, error });

const getAvailableCompaniesRequest = () => ({ type: salesConstants.GET_COMPANY_REQUEST });
const getAvailableCompaniesSuccess = availableCompanies => ({ type: salesConstants.GET_COMPANY_SUCCESS, availableCompanies });
const getAvailableCompaniesFailure = error => ({ type: salesConstants.GET_COMPANY_ERROR, error });

const getAvailableWarehouseRequest = () => ({ type: salesConstants.GET_WAREHOUSE_REQUEST });
const getAvailableWarehouseSuccess = availableWarehouses => ({ type: salesConstants.GET_WAREHOUSE_SUCCESS, availableWarehouses });
const getAvailableWarehouseFailure = error => ({ type: salesConstants.GET_WAREHOUSE_ERROR, error });

const getAvailableCashRequest = () => ({ type: salesConstants.GET_CASH_REQUEST });
const getAvailableCashSuccess = availableCash => ({ type: salesConstants.GET_CASH_SUCCESS, availableCash });
const getAvailableCashFailure = error => ({ type: salesConstants.GET_CASH_ERROR, error });

const setActiveCompany = company => ({ type: salesConstants.SET_ACTIVE_COMPANY, company });
const setActiveWarehouse = warehouse => ({ type: salesConstants.SET_ACTIVE_WAREHOUSE, warehouse });
const setActiveCash = cash => ({ type: salesConstants.SET_ACTIVE_CASH, cash });

const clearState = () => ({ type: salesConstants.CLEAR_STATE });
const clearInvoiceState = () => ({ type: salesConstants.CLEAR_INVOICE_STATE });

const resetInvoice = () => ({ type: salesConstants.RESET_INVOICE });



export const getCompanies = () => async (dispatch) => {
    dispatch(getAvailableCompaniesRequest());
    try {
        const companiesResponse = await productService.getCompanies();
        dispatch(getAvailableCompaniesSuccess(companiesResponse));
        // dispatch(getWarehouse());
        // dispatch(getCash());
    } catch (error) {
        const notification = { title: "Error al obtener empresas", message: error.response ? error.response.data.message : "Error en la conexión" };
        dispatch(getAvailableCompaniesFailure(error.response ? error.response.data : "Error en la conexión"));
        dispatch(errorNotification(notification));
    }
};

export const getWarehouse = () => async (dispatch) => {
    dispatch(getAvailableWarehouseRequest());
    try {
        const warehouseResponse = await productService.getWarehouse();
        dispatch(getAvailableWarehouseSuccess(warehouseResponse));
    } catch (error) {
        const notification = { title: "Error al obtener bodegas", message: error.response ? error.response.data.message : "Error en la conexión" };
        dispatch(getAvailableWarehouseFailure(error.response ? error.response.data : "Error en la conexión"));
        dispatch(errorNotification(notification));
    }
};

export const getCash = () => async (dispatch) => {
    dispatch(getAvailableCashRequest());
    try {
        const warehouseResponse = await productService.getCash();
        dispatch(getAvailableCashSuccess(warehouseResponse));

    } catch (error) {
        const notification = { title: "Error al obtener cajas", message: error.response ? error.response.data.message : "Error en la conexión" };
        dispatch(getAvailableCashFailure(error.response ? error.response.data : "Error en la conexión"));
        dispatch(errorNotification(notification));
    }
};

export const generateInvoice = (invoiceRequest) => async (dispatch) => {
    dispatch(generateInvoiceRequest());
    try {
        const invoiceResponse = await productService.generateInvoice(invoiceRequest);
        dispatch(generateInvoiceSuccess(invoiceResponse));
        if(invoiceResponse.downloadLink)
        {dispatch(downloadFile(invoiceResponse.downloadLink, "Invoice.pdf", 'application/pdf'));}
        dispatch(clearState());
        const notification = { title: "Factura generada exitosamente", message: 'Su factura ha sido generada' };
        // dispatch(successNotification(notification));
    } catch (error) {
        const notification = { title: "Error al generar factura", message: error.response ? error.response.data.message : "Error en la conexión" };
        dispatch(generateInvoiceFailure(error.response ? error.response.data : "Error en la conexión"));
        dispatch(errorNotification(notification));
    }
};

export const downloadInvoice = (invoiceId) => async (dispatch) => {
    dispatch(downloadInvoiceRequest());
    try {
        dispatch(downloadInvoicePdfFile(invoiceId, "Invoice.pdf", 'application/pdf'));

        // const notification = { title: "Factura generada exitosamente", message: 'Su factura ha sido generada' };
        // dispatch(successNotification(notification));
    } catch (error) {
        const notification = { title: "Error al generar factura", message: error.response ? error.response.data.message : "Error en la conexión" };
        dispatch(downloadInvoiceFailure(error.response ? error.response.data : "Error en la conexión"));
        dispatch(errorNotification(notification));
    }
};

export const getAvailableProducts = (clue) => async (dispatch) => {
    dispatch(getAvailableProductsRequest());
    try {
        const availableProducts = await productService.getAvailableProducts(clue);
        dispatch(getAvailableProductsSuccess(availableProducts));
    } catch (error) {
        const notification = { title: "Error al obtener productos", message: error.response ? error.response.data.message : "Error en la conexión" };
        dispatch(getAvailableProductsFailure(error.response ? error.response.data : "Error en la conexión"));
        dispatch(errorNotification(notification));
    }
};

export const getAvailableClients = (clue) => async (dispatch) => {
    dispatch(getAvailableClientsRequest());
    try {
        const availableClients = await productService.getClientByClue(clue);
        dispatch(getAvailableClientsSuccess(availableClients));
    } catch (error) {
        const notification = { title: "Error al obtener clientes", message: error.response ? error.response.data.message : "Error en la conexión" };
        dispatch(getAvailableClientsFailure(error.response ? error.response.data : "Error en la conexión"));
        dispatch(errorNotification(notification));
    }
};

export const getInvoiceList = (startDate, endDate) => async (dispatch) => {
    dispatch(getInvoiceListRequest());
    try {
        const invoices = await productService.getInvoiceList(startDate, endDate);
        dispatch(getInvoiceListSuccess(invoices));
    } catch (error) {
        const notification = { title: "Error al obtener facturas", message: error.response ? error.response.data.message : "Error en la conexión" };
        dispatch(getInvoiceListFailure(error.response ? error.response.data : "Error en la conexión"));
        dispatch(errorNotification(notification));
    }
};

export const createClient = (request) => async (dispatch) => {
    dispatch(createClientRequest());
    try {
        const clientCreated = await productService.createClient(request);
        dispatch(createClientSuccess(clientCreated));
    } catch (error) {
        const notification = { title: "Error al obtener clientes", message: error.response ? error.response.data.message : "Error en la conexión" };
        dispatch(createClientFailure(error.response ? error.response.data : "Error en la conexión"));
        dispatch(errorNotification(notification));
    }
};

export const cashTransfer = (request) => async (dispatch) => {
    dispatch(cashTransferRequest());
    try {
        const transferResponse = await productService.localTransfer(request);
        dispatch(cashTransferSuccess(transferResponse));
        const notification = { title: "Transferencia realizada", message: '' };
        dispatch(Notifications.success(notification));
    } catch (error) {
        const notification = { title: "Error al realizar transferencia", message: error.response ? error.response.data.message : "Error en la conexión" };
        dispatch(cashTransferFailure(error.response ? error.response.data : "Error en la conexión"));
        dispatch(errorNotification(notification));
    }
};

export const getAvailablePrice = (productId) => async (dispatch) => {
    dispatch(getAvailableProductsPriceRequest());
    try {
        const availablePrice = await productService.getAvailablePrice(productId);
        dispatch(getAvailableProductsPriceSuccess(availablePrice));
    } catch (error) {
        const notification = { title: "Error al obtener precios para el producto", message: error.response ? error.response.data.message : "Error en la conexión" };
        dispatch(getAvailableProductsPriceFailure(error.response ? error.response.data : "Error en la conexión"));
        dispatch(errorNotification(notification));
    }
};

export const clearProductsPrices = () => (dispatch) => {
    dispatch(clearProductsPrice());
};

export const setCompany = (companyId) => (dispatch) => {
    $http.defaults.headers.common['ININ-Company'] = companyId;
};

export const setUserCompany = (company) => (dispatch) => {
    dispatch(setActiveCompany(company));
    dispatch(setCompany(company.companyId));
};

export const setUserWarehouse = (warehouse) => (dispatch) => {
    dispatch(setActiveWarehouse(warehouse));
};

export const setUserCash = (cash) => (dispatch) => {
    dispatch(setActiveCash(cash));
};

export const clearSalesReducer = () => (dispatch) => {
    dispatch(clearState());
};

export const clearInvoiceSalesReducer = () => (dispatch) => {
    dispatch(clearInvoiceState());
};

export const invoiceReseted = () => (dispatch) => {
    dispatch(resetInvoice());
};