import UsersController from '../services/user.service';
import {error as errorNotification, success as successNotification} from 'react-notification-system-redux';
import {history} from "../App";
import {clearNitSearch} from "./nit.actions";
import { getCompanies, clearInvoiceSalesReducer } from "./product.actions";

export const actionTypes = {

    GET_COUNTRY: 'GET_COUNTRY',
    GET_COUNTRY_REQUEST: 'GET_COUNTRY_REQUEST',
    GET_COUNTRY_SUCCESS: 'GET_COUNTRY_SUCCESS',
    GET_COUNTRY_ERROR: 'GET_COUNTRY_ERROR',

    RECOVER_PASSWORD: 'RECOVER_PASSWORD',
    RECOVER_PASSWORD_REQUEST: 'RECOVER_PASSWORD_REQUEST',
    RECOVER_PASSWORD_SUCCESS: 'RECOVER_PASSWORD_SUCCESS',
    RECOVER_PASSWORD_ERROR: 'RECOVER_PASSWORD_ERROR',

    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',

    RECOVER_TOKEN: 'RECOVER_TOKEN',
    RECOVER_TOKEN_REQUEST: 'RECOVER_TOKEN_REQUEST',
    RECOVER_TOKEN_SUCCESS: 'RECOVER_TOKEN_SUCCESS',
    RECOVER_TOKEN_ERROR: 'RECOVER_TOKEN_ERROR',

    VALIDATE_TOKEN: 'VALIDATE_TOKEN',
    VALIDATE_TOKEN_REQUEST: 'VALIDATE_TOKEN_REQUEST',
    VALIDATE_TOKEN_SUCCESS: 'VALIDATE_TOKEN_SUCCESS',
    VALIDATE_TOKEN_ERROR: 'VALIDATE_TOKEN_ERROR',

    RESEND_EMAIL: 'RESEND_EMAIL',
    RESEND_EMAIL_REQUEST: 'RESEND_EMAIL_REQUEST',
    RESEND_EMAIL_SUCCESS: 'RESEND_EMAIL_SUCCESS',
    RESEND_EMAIL_ERROR: 'RESEND_EMAIL_ERROR',

    LOGIN: 'LOGIN',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',

    REGISTER: 'REGISTER',
    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_ERROR: 'REGISTER_ERROR',

    REFRESH: 'REFRESH',
    REFRESH_REQUEST: 'REFRESH_REQUEST',
    REFRESH_SUCCESS: 'REFRESH_SUCCESS',
    REFRESH_ERROR: 'REFRESH_ERROR',

    ACCEPTED_TERMS: 'ACCEPTED_TERMS',
    ACCEPTED_TERMS_REQUEST: 'ACCEPTED_TERMS_REQUEST',
    ACCEPTED_TERMS_SUCCESS: 'ACCEPTED_TERMS_SUCCESS',
    ACCEPTED_TERMS_ERROR: 'ACCEPTED_TERMS_ERROR',

    ACCEPT_TERMS: 'ACCEPT_TERMS',
    ACCEPT_TERMS_REQUEST: 'ACCEPT_TERMS_REQUEST',
    ACCEPT_TERMS_SUCCESS: 'ACCEPT_TERMS_SUCCESS',
    ACCEPT_TERMS_ERROR: 'ACCEPT_TERMS_ERROR',

    GET_EXTRA_DATA: 'GET_EXTRA_DATA',
    GET_EXTRA_DATA_REQUEST: 'GET_EXTRA_DATA_REQUEST',
    GET_EXTRA_DATA_SUCCESS: 'GET_EXTRA_DATA_SUCCESS',
    GET_EXTRA_DATA_ERROR: 'GET_EXTRA_DATA_ERROR',

    SAVE_EXTRA_DATA: 'SAVE_EXTRA_DATA',
    SAVE_EXTRA_DATA_REQUEST: 'SAVE_EXTRA_DATA_REQUEST',
    SAVE_EXTRA_DATA_SUCCESS: 'SAVE_EXTRA_DATA_SUCCESS',
    SAVE_EXTRA_DATA_ERROR: 'SAVE_EXTRA_DATA_ERROR',

    LOGOUT: 'LOGOUT',
    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_ERROR: 'LOGOUT_ERROR',
};


const getCountryRequest = () => ({ type: actionTypes.GET_COUNTRY_REQUEST });
const getCountrySuccess = data => ({ type: actionTypes.GET_COUNTRY_SUCCESS, data, });
const getCountryFailure = error => ({ type: actionTypes.GET_COUNTRY_ERROR, error, });

const recoverPasswordRequest = () => ({type: actionTypes.RECOVER_PASSWORD_REQUEST});
const recoverPasswordSuccess = user => ({type: actionTypes.RECOVER_PASSWORD_SUCCESS, user,});
const recoverPasswordError = error => ({type: actionTypes.RECOVER_PASSWORD_ERROR, error,});

const changePasswordRequest = () => ({type: actionTypes.CHANGE_PASSWORD_REQUEST});
const changePasswordSuccess = user => ({type: actionTypes.CHANGE_PASSWORD_SUCCESS, user,});
const changePasswordError = error => ({type: actionTypes.CHANGE_PASSWORD_ERROR, error,});

const recoverTokenRequest = () => ({type: actionTypes.RECOVER_TOKEN_REQUEST});
const recoverTokenSuccess = user => ({type: actionTypes.RECOVER_TOKEN_SUCCESS, user,});
const recoverTokenError = error => ({type: actionTypes.RECOVER_TOKEN_ERROR, error,});

const validateTokenRequest = () => ({type: actionTypes.VALIDATE_TOKEN_REQUEST});
const validateTokenSuccess = user => ({type: actionTypes.VALIDATE_TOKEN_SUCCESS, user,});
const validateTokenError = error => ({type: actionTypes.VALIDATE_TOKEN_ERROR, error,});

const resendEmailRequest = () => ({type: actionTypes.RESEND_EMAIL_REQUEST});
const resendEmailSuccess = user => ({type: actionTypes.RESEND_EMAIL_SUCCESS, user,});
const resendEmailError = error => ({type: actionTypes.RESEND_EMAIL_ERROR, error,});

const loginRequest = () => ({type: actionTypes.LOGIN_REQUEST});
const loginSuccess = user => ({type: actionTypes.LOGIN_SUCCESS, user,});
const loginError = error => ({type: actionTypes.LOGIN_ERROR, error,});

const registerRequest = () => ({type: actionTypes.REGISTER_REQUEST});
const registerSuccess = user => ({type: actionTypes.REGISTER_SUCCESS, user,});
const registerError = error => ({type: actionTypes.REGISTER_ERROR, error,});

const refreshRequest = () => ({type: actionTypes.LOGIN_REQUEST,});
const refreshError = error => ({type: actionTypes.LOGIN_ERROR, error,});
const refreshSuccess = user => ({type: actionTypes.LOGIN_SUCCESS, user,});

const userAcceptedTermsRequest = () => ({ type: actionTypes.ACCEPTED_TERMS_REQUEST });
const userAcceptedTermsSuccess = value => ({ type: actionTypes.ACCEPTED_TERMS_SUCCESS, value, });
const userAcceptedTermsFailure = error => ({ type: actionTypes.ACCEPTED_TERMS_ERROR, error, });

const userAcceptTermsRequest = () => ({ type: actionTypes.ACCEPT_TERMS_REQUEST });
const userAcceptTermsSuccess = () => ({ type: actionTypes.ACCEPT_TERMS_SUCCESS });
const userAcceptTermsFailure = error => ({ type: actionTypes.ACCEPT_TERMS_ERROR, error, });

const getExtraDataRequest = () => ({type: actionTypes.GET_EXTRA_DATA_REQUEST});
const getExtraDataSuccess = (data) => ({type: actionTypes.GET_EXTRA_DATA_SUCCESS, data});
const getExtraDataError = (error) => ({type: actionTypes.GET_EXTRA_DATA_ERROR, error});

const saveExtraDataRequest = () => ({type: actionTypes.SAVE_EXTRA_DATA_REQUEST});
const saveExtraDataSuccess = (data) => ({type: actionTypes.SAVE_EXTRA_DATA_SUCCESS, data});
const saveExtraDataError = (error) => ({type: actionTypes.SAVE_EXTRA_DATA_ERROR, error});

const logoutRequest = () => ({type: actionTypes.LOGOUT_REQUEST});
const logoutSuccess = () => ({type: actionTypes.LOGOUT_SUCCESS});
const logoutError = error => ({type: actionTypes.LOGOUT_ERROR, error,});

const notification = (title, message, error) => dispatch =>{
    if(!error) {
        dispatch( successNotification({title, message}));
    } else {
        dispatch( errorNotification({title, message}));
    }
    return true;
};

export const getCountries = () => async (dispatch) => {
    dispatch(getCountryRequest());
    try{
        const result = await UsersController.getCountries();
        dispatch(getCountrySuccess(result));
    }catch(error){
        if (error.response && error.response.data.message) {
            dispatch(notification("Registro","Error al obtener la lista de países", true));
        }
        dispatch(registerError(error.response.data))
    }
};

export const login = (email, password) => async (dispatch) => {
    dispatch(loginRequest());
    try {
        const user = await UsersController.login(email, password);
        dispatch(loginSuccess(user));
        dispatch(getCompanies());
    } catch (error) {

        if(error.response && error.response.data.message){
            const notification = {title: "Error de autenticación", message: error.response.data.message};
            dispatch(loginError(error.response.data));
            dispatch(errorNotification(notification));
        } else {
            const notification = {title: "Error de autenticación", message: "Por favor intente más tarde"};
            dispatch(loginError({}));
            dispatch(errorNotification(notification));
        }
    }
};

export const register = (country, email, pass) => async (dispatch) => {
    dispatch(registerRequest());
    try {
        if(country){
            const result = await UsersController.register(country.value, email, pass);
            dispatch(registerSuccess(result));
        }  else {
            dispatch(notification('Registro','Error en los datos transmitidos', true));
            dispatch(registerError({message:"Error en los datos transmitidos", data:{country: 'Campo obligatorio'}}))
        }

    } catch (error) {
        if (error.response && error.response.data.message) {
            dispatch(notification('Registro',error.response.data.message,true));
        }
        dispatch(registerError(error.response.data))
    }
};

export const recoverPassword = (email) => async (dispatch) => {
    dispatch(recoverPasswordRequest());
    try {
        const result = await  UsersController.recoverPassword(email);
        dispatch(recoverPasswordSuccess(result));
        if (result.message) {
            const notification = {message: result.message};
            dispatch(successNotification(notification));
            history.replace(`${process.env.PUBLIC_URL}/login`);
        }
    } catch (error) {
        if (error.response && error.response.data.message) {
            const notification = {title: "Error al recuperar contraseña", message: error.response.data.message};
            dispatch(errorNotification(notification));
        }
        dispatch(recoverPasswordError(error.response.data))
    }
};

export const changePassword = (token, password, password_confirmation) => async (dispatch) => {
    dispatch(changePasswordRequest());
    try {
        const result = await  UsersController.changePassword(token, password, password_confirmation);
        dispatch(changePasswordSuccess(result));
        const notification = {title: 'Éxito', message: result.message};
        dispatch(successNotification(notification));
        history.replace(`${process.env.PUBLIC_URL}/login`);

    } catch (error) {
        if (error.response && error.response.data.message) {
            const notification = {title: "Error al cambiar contraseña", message: error.response.data.message};
            dispatch(errorNotification(notification));
        }
        dispatch(changePasswordError(error.response.data))
    }
};

export const recoverToken = (token) => async (dispatch) => {
    dispatch(recoverTokenRequest());
    try {
        const isValidToken = await  UsersController.recoverToken(token);
        dispatch(recoverTokenSuccess(isValidToken));
    } catch (error) {
        const notification = {title: "Error", message: error.response.data.message};
        dispatch(recoverTokenError(error.response.data));
        dispatch(errorNotification(notification));
        history.replace(`${process.env.PUBLIC_URL}/`);
    }
};

export const validateToken = (token) => async (dispatch) => {
    dispatch(validateTokenRequest());
    try {
        const isValidToken = await  UsersController.validateToken(token);
        dispatch(validateTokenSuccess(isValidToken));
        dispatch(notification("Felicidades",'Tu correo ha sido validado exitosamente.'))
    } catch (error) {
        const notification = {title: "Error", message: error.response.data.message};
        dispatch(validateTokenError(error.response.data));
        dispatch(errorNotification(notification));
    }
    history.replace(`${process.env.PUBLIC_URL}/`);

};

export const resendEmail = (email) => async (dispatch) => {
    dispatch(resendEmailRequest());
    try {
        const result = await  UsersController.resendEmail(email);
        const notification = {title: 'Éxito', message: result.message};

        dispatch(resendEmailSuccess(result));
        dispatch(successNotification(notification));

        history.replace(`${process.env.PUBLIC_URL}/`);
    } catch (error) {
        if (error.response.data.message) {
            const notification = {title: "Error al reenviar correo electrónico", message: error.response.data.message};
            dispatch(errorNotification(notification));
        }
        dispatch(resendEmailError(error.response.data))
    }
};

export const refreshSession = () => async (dispatch) => {
    dispatch(refreshRequest());
    try {
        const user = await UsersController.refreshSession();
        dispatch(refreshSuccess(user));
    } catch (error) {
        dispatch(refreshError(error.response.data));
    }
};

export const userAcceptTerms = () => async (dispatch) => {
    dispatch(userAcceptTermsRequest());
    try{
        await UsersController.acceptTerms();
        dispatch(userAcceptTermsSuccess());
        history.replace(`${process.env.PUBLIC_URL}/cv`);
    }catch(error){
        const notification = { title: "Error al obtener confirmación de terminos", message: error.response.data.message };
        dispatch(userAcceptTermsFailure(error.response.data));
        dispatch(errorNotification(notification));
    }
};

export const userAcceptedTerms = () => async (dispatch) => {
    dispatch(userAcceptedTermsRequest());
    try{
        const aceptedTerms = await UsersController.acceptedTerms();
        dispatch(userAcceptedTermsSuccess(aceptedTerms));
    }catch(error){
        const notification = {
            title: "Error al obtener confirmación de terminos",
            message: error.response? error.response.data.message : "Error en la conexión"
        };
        dispatch(userAcceptedTermsFailure(error.response? error.response.data: "Error en la conexión"));
        dispatch(errorNotification(notification));
    }
};


export const saveExtraData = (data) => async (dispatch) => {
    dispatch(saveExtraDataRequest());
    try {
        await  UsersController.saveExtraData(data);
        dispatch(saveExtraDataSuccess(data));
        dispatch(refreshSession());
    } catch (error) {
        dispatch(saveExtraDataError(error));
    }
};

export const logout = () => dispatch => {
    dispatch(logoutRequest());
    const result = UsersController.logout();
    dispatch(clearInvoiceSalesReducer());
    if (result) {
        dispatch(logoutSuccess())
    } else {
        const notification = {title: "Error", message: 'Error al tratar de salir del sistema'};
        dispatch(errorNotification(notification));
        dispatch(logoutError("Error al tratar de salir del sistema"));
    }
};

