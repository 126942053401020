import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './App';
import {Provider} from 'react-redux';
import {store} from './reducers';
import registerServiceWorker from './registerServiceWorker';
import {PersistGate} from "redux-persist/integration/react";
import {persistor} from "./reducers/index";
import ReactBreakpoints from 'react-breakpoints';
import {$http} from './services/http';
import {selectToken} from "./selectors/user.selector";
import {selectActiveCompany} from "./selectors/sales.selector";

const breakpoints = {
    mobile: 360,
    mobileLandscape: 480,
    tablet: 768,
    tabletLandscape: 1024,
    desktop: 1200,
    desktopLarge: 1500,
    desktopWide: 1920,
};

function onBeforeLift (){
    const state = store.getState();
    const token = selectToken(state);
    const company = selectActiveCompany(state);
    if(token!== null ){
        $http.defaults.headers.common['Authorization'] = token;
    }
    if(company!== null ){
        $http.defaults.headers.common['ININ-Company'] = company.id;
        // $http.defaults.headers.common['ININ-Company'] = '618';
    }
}

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} onBeforeLift={onBeforeLift}>
            <ReactBreakpoints breakpoints={breakpoints}>
                <App/>
            </ReactBreakpoints>
        </PersistGate>
    </Provider>
    ,
    document.getElementById('root')
);
registerServiceWorker();