import {$http} from './http';

class ChartsController {

    getIncomeVsExpensesData = async (company,year) => {
        try {
            const result = await $http.get(`/erp/accounting/report/graph/landing/incomevsexpenses/${company}/${year}?iva=true`);
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    getExpensesData = async (company, year, month) => {
        try {
            let url = month>11?
                `/erp/accounting/report/graph/landing/expenses/${company}/${year}?iva=true`:
                `/erp/accounting/report/graph/landing/expenses/${company}/${year}/${month}?iva=true`;

            const result = await $http.get(url);
            return result.data.data;
        } catch (error) {
            throw error;
        }
    }
}

export default new ChartsController();