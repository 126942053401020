import DeclarationsController from '../services/declarations.service';
import {error as errorNotification, success as successNotification} from 'react-notification-system-redux';
import {history} from "../App";
import saveAs from 'file-saver';

export const actionTypes = {
    GET_MONTHLY_FORMS: 'GET_MONTHLY_FORMS',
    GET_MONTHLY_FORMS_REQUEST: 'GET_MONTHLY_FORMS_REQUEST',
    GET_MONTHLY_FORMS_SUCCESS: 'GET_MONTHLY_FORMS_SUCCESS',
    GET_MONTHLY_FORMS_ERROR: 'GET_MONTHLY_FORMS_ERROR',

    CLOSE_MONTH: 'CLOSE_MONTH',
    CLOSE_MONTH_REQUEST: 'CLOSE_MONTH_REQUEST',
    CLOSE_MONTH_SUCCESS: 'CLOSE_MONTH_SUCCESS',
    CLOSE_MONTH_ERROR: 'CLOSE_MONTH_ERROR',

    SELECTED_DECLARATION_MONTH: 'SELECTED_DECLARATION_MONTH',
    SELECTED_DECLARATION_MONTH_REQUEST: 'SELECTED_DECLARATION_MONTH_REQUEST',
    SELECTED_DECLARATION_MONTH_SUCCESS: 'SELECTED_DECLARATION_MONTH_SUCCESS',
    SELECTED_DECLARATION_MONTH_ERROR: 'SELECTED_DECLARATION_MONTH_ERROR',

    FORM_PAID: 'FORM_PAID',
    FORM_PAID_REQUEST: 'FORM_PAID_REQUEST',
    FORM_PAID_SUCCESS: 'FORM_PAID_SUCCESS',
    FORM_PAID_ERROR: 'FORM_PAID_ERROR',

    DOWNLOAD_FORM_DOCUMENT: 'DOWNLOAD_FORM_DOCUMENT',
    DOWNLOAD_FORM_DOCUMENT_REQUEST: 'DOWNLOAD_FORM_DOCUMENT_REQUEST',
    DOWNLOAD_FORM_DOCUMENT_SUCCESS: 'DOWNLOAD_FORM_DOCUMENT_SUCCESS',
    DOWNLOAD_FORM_DOCUMENT_ERROR: 'DOWNLOAD_FORM_DOCUMENT_ERROR',

};

const getMonthlyFormsRequest = () => ({type: actionTypes.GET_MONTHLY_FORMS_REQUEST});
const getMonthlyFormsSuccess = (data) => ({type: actionTypes.GET_MONTHLY_FORMS_SUCCESS, data});
const getMonthlyFormsError = (error) => ({type: actionTypes.GET_MONTHLY_FORMS_ERROR});

const closeMonthRequest = () => ({type: actionTypes.CLOSE_MONTH_REQUEST});
const closeMonthSuccess = (data) => ({type: actionTypes.CLOSE_MONTH_SUCCESS, data});
const closeMonthError = (error) => ({type: actionTypes.CLOSE_MONTH_ERROR});

const formPaidRequest = () => ({type: actionTypes.FORM_PAID_REQUEST});
const formPaidSuccess = (data) => ({type: actionTypes.FORM_PAID_SUCCESS, data});
const formPaidError = (error) => ({type: actionTypes.FORM_PAID_ERROR});

const selectedMonthRequest = () => ({type: actionTypes.SELECTED_DECLARATION_MONTH_REQUEST});
const selectedMonthSuccess = (data) => ({type: actionTypes.SELECTED_DECLARATION_MONTH_SUCCESS, data});
const selectedMonthError = (error) => ({type: actionTypes.SELECTED_DECLARATION_MONTH_ERROR});

const downloadFormDocumentRequest = () => ({type: actionTypes.DOWNLOAD_FORM_DOCUMENT_REQUEST});
const downloadFormDocumentSuccess = (data) => ({type: actionTypes.DOWNLOAD_FORM_DOCUMENT_SUCCESS, data});
const downloadFormDocumentError = (error) => ({type: actionTypes.DOWNLOAD_FORM_DOCUMENT_ERROR});


export const notification = (title, message, error) => dispatch => {
    if (!error) {
        dispatch(successNotification({title, message}));
    } else {
        dispatch(errorNotification({title, message}));
    }
    return true;
};

export const getMonthlyFormsData = (year) => async (dispatch) => {
    dispatch(getMonthlyFormsRequest());
    try {
        const data = await DeclarationsController.getMonthlyForms(year);
        dispatch(getMonthlyFormsSuccess(data));
    } catch (error) {
        dispatch(getMonthlyFormsError(error.response.data));
    }
};

export const closeMonth = (year, month) => async (dispatch) => {
    dispatch(closeMonthRequest());
    try {
        const data = await DeclarationsController.closeMonth(year, month);
        dispatch(getMonthlyFormsData(year));
        dispatch(closeMonthSuccess(data));
        dispatch(notification("Declaraciones", "Mes contable cerrado exitosamente"))
    } catch (error) {
        console.log(error);
        if (error.response.data) {
            dispatch(closeMonthError(error.response.data));
            dispatch(notification("Declaraciones", error.response.data.message, true));
        }
        dispatch(closeMonthError({message: "Error, intente más tarde"}));
    }
};

export const selectDeclarationMonth = (year, month) => dispatch => {

    let today = new Date();
    dispatch(selectedMonthRequest());
    try {
        const y = parseInt(year, 10);
        const m = parseInt(month, 10);
        if (y < 2018 || y > today.getFullYear() || m < 0 || m > 12) {
            dispatch(notification("Declaraciones", "Las declaraciones solicitadas no fueron encontradas", true));
            dispatch(selectedMonthError({message: "Declaraciones no encontradas"}));
            history.replace(`${process.env.PUBLIC_URL}/declaraciones`);
        }
        dispatch(selectedMonthSuccess({year: y, month: m}));
    } catch (error) {
        dispatch(notification("Declaraciones", "Las declaraciones solicitadas no fueron encontradas", true));
        dispatch(selectedMonthError({message: "Declaraciones no encontradas"}))
    }
};

export const formPaid = (form, year) => async (dispatch) => {
    dispatch(formPaidRequest());
    try {
        const data = await DeclarationsController.formPaid(form);
        dispatch(getMonthlyFormsData(year));
        dispatch(formPaidSuccess(data));
        dispatch(notification("Declaraciones","Reporte de pago enviado exitosamente"));
    } catch (error) {
        if (error.response.data) {
            dispatch(formPaidError(error.response.data));
            dispatch(notification("Declaraciones", error.response.data.message, true));
        }
        dispatch(formPaidError({message: "Error, intente más tarde"}));
    }
};


export const getFormDocument = (form) => async (dispatch) => {
    dispatch(downloadFormDocumentRequest());
    const name = form.fileName;
    const mime = 'application/pdf';
    try {
        const result = await DeclarationsController.getFormDocumentLink(form);
        dispatch(downloadFormDocumentSuccess(result));
        dispatch(downloadFile(result, name, mime));
        dispatch(clearFormDocumentResult())
    } catch (error) {
        console.log(error);
        if (error.response.data) {
            dispatch(downloadFormDocumentError(error.response.data));
            dispatch(notification("Declaraciones", error.response.data.message, true));
        }
        dispatch(downloadFormDocumentError({message: "Error, intente más tarde"}));
    }
};

export const clearFormDocumentResult = () => dispatch => {
    dispatch(downloadFormDocumentSuccess(null));
};

export const downloadFile = (link, name, type) => async dispatch => {

    try {
        const result = await DeclarationsController.downloadFile(link);
        saveAs(new Blob([result]), name, {type: type});
    } catch (error) {
        console.log(error);
        dispatch(notification('Reportes', "Error al descargar el reporte, intente más tarde.", true))
    }

};