import { $http } from './http';

export class productService{

    getAvailableProducts = async (clue) => {
        try {
            const result = await $http.get(`erp/inventory/product/search/existing?bought=false&clue=${clue}&sold=true`, '');
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    getAvailablePrice = async (productId) => {
        try {
            const result = await $http.get(`erp/inventory/product/price/${productId}`, '');
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    getClientByClue = async (clue) => {
        try {
            const result = await $http.get(`erp/inventory/vendor/search/existing?client=true&clue=${clue}&onlyActive=true&provider=false`, '');
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    generateInvoice = async (invoiceRequest) => {
        try {
            const result = await $http.post(`erp/sale/invoices`, invoiceRequest);
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    getCompanies = async () => {
        try {
            const result = await $http.get(`role/user`, '');
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    getWarehouse = async () => {
        try {
            const result = await $http.get(`erp/inventory/warehouse/search/existing?clue=`, '');
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    getCash = async () => {
        try {
            const result = await $http.get(`erp/sale/invoices/cash/search?clue=`, '');
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    createClient = async (clientRequest) => {
        try {
            const result = await $http.post(`erp/inventory/vendor`, clientRequest);
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    getInvoiceList = async (startDate, endDate) => {
        try {
            const result = await $http.get(`erp/sale/invoices/search?endDate=${endDate}&startDate=${startDate}`, '');
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    localTransfer = async (request) => {
        try {
            const result = await $http.post(`erp/bank/localtransfer`, request);
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

}

export default new productService();