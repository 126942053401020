import * as React from 'react';
import {connect} from 'react-redux';
import LoginContainer from '../Login/LoginContainer';
import {loadingSelector} from "../../selectors/loading.selector";
import {singleErrorSelector} from "../../selectors/error.selector";
import {selectToken} from "../../selectors/user.selector";
import {selectName, selectTaxRegime} from "../../selectors/nit.selector";
import {actionTypes, register} from "../../actions/user.actions";
import {actionTypes as nitActionTypes, findByNit} from "../../actions/nit.actions";
import '../Login/login.css';
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import RegistrationForm from "./RegistrationForm";
import {history} from "../../App";

class Registration extends React.Component {


    componentDidUpdate() {
        const {token} = this.props;
        if (token !== null ) {
            this.props.history.replace(`${process.env.PUBLIC_URL}/`);
        }
    }


    getRegisterFooter = () => {
        return (
            <div className={"login-footer orange"}>
                <Row>
                    <Col xs={2}>
                        <i className={"fas fa-exclamation-triangle"} style={{fontSize: 32, paddingTop:7, color:'#EE9800'}}/>
                    </Col>
                    <Col xs={10} style={{textAlign:'justify', fontSize:12}}>
                        Tu contraseña debe de contener al menos 8 caracteres, incluidas mayúsculas, minúsculas y números para mayor seguridad.
                    </Col>
                </Row>
            </div>
        );
    };

    toLogin = (key) => {
        if (key === 1) history.replace(`${process.env.PUBLIC_URL}/login`)
    };

    render() {

        return (
            <LoginContainer footer={this.getRegisterFooter()}>
                <Tabs defaultActiveKey={2} onSelect={this.toLogin} animation={false} id={'registration-tabs'}>
                    <Tab eventKey={1} title="INGRESAR">
                    </Tab>
                    <Tab eventKey={2} title="REGISTRARME">
                        <RegistrationForm/>
                    </Tab>
                </Tabs>
            </LoginContainer>
        );

    }

}

const mapStateToProps = state => ({
    isRegisterLoading: loadingSelector([actionTypes.REGISTER])(state),
    registerErrors: singleErrorSelector([actionTypes.REGISTER])(state),

    token: selectToken(state),
});

const mapDispatchToProps = dispatch => ({
    register: (nit, name, email, password, taxRegime) => dispatch(register(nit, name, email, password, taxRegime)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);


