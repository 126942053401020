import {$http} from "./http";

class PaymentsController {

    getServiceStatus = async () => {
        try{
            const result = await $http.get('/company/balance/service/status?service=20');
            const data = result.data.data;
            const hiringDate = data.hiringDate? new Date(data.hiringDate) : new Date();
            const cutOffDate = data.cutOffDate? new Date(data.cutOffDate) : new Date();
            return{hiringDate, cutOffDate};
        }catch (error){
            throw error;
        }
    }

}

export default new PaymentsController();