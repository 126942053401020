import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {logout} from "../../actions/user.actions";
import {selectMenuCollapsed, selectMenuSelected} from "../../selectors/dashboard.selector";
import {selectMenu} from "../../actions/dashboard.actions";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";

class Menu extends Component {


    logout = () => this.props.logout();
    selectMenu = (val) => this.props.selectMenu(val);
    tooltip = text => {
        return this.props.expanded? <Tooltip id="tooltip"> {text} </Tooltip> : <div/>;
    };

    render() {
        const {selected} = this.props;
        const options = (
            <ul className={"nav side-menu"}>
                {this.props.options.map((option) =>
                    <OverlayTrigger placement="left" overlay={this.tooltip(option.text)} key={option.id}>
                    <li onClick={() => this.selectMenu(option.id)} className={selected === option.id ? 'activo' : ''}>
                        <Link to={`${process.env.PUBLIC_URL}${option.link}`}>
                            <Row>
                                <Col xs={2} style={{textAlign: 'center'}}>
                                    <i className={`fas fa-${option.icon}`}/>
                                </Col>
                                <Col xs={10}>
                                    {option.text}
                                </Col>
                            </Row>
                        </Link>
                    </li>
                    </OverlayTrigger>
                )}
                <OverlayTrigger placement="left" overlay={this.tooltip('Salir')}>
                <li>
                    <Link to={`${process.env.PUBLIC_URL}/`} onClick={this.logout}>
                        <Row>
                            <Col xs={2} style={{textAlign: 'center'}}>
                                <i className={'fas fa-sign-out-alt'}/>
                            </Col>
                            <Col xs={10}>
                                Salir
                            </Col>
                        </Row>

                    </Link>
                </li>
                </OverlayTrigger>
            </ul>
        );

        return (
            <div id="sidebar-menu" className={"main_menu_side hidden-print main_menu"}>
                <div className={"menu_section"}>
                    {options}
                </div>
            </div>
        );

    }
}

const mapStateToProps = state => ({
    selected: selectMenuSelected(state),
    expanded: selectMenuCollapsed(state),
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
    selectMenu: (val) => dispatch(selectMenu(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);