import {$http} from './http';

class NITController {

    findByNit = async (nit) => {
        try {
            const result = await $http.get(`/search/nit?nit=${nit}`);
            return result.data.data;
        } catch (error) {
            throw  error;
        }
    };

}

export default new NITController();