const salesConstants = {
    //----------------Assigned Tests
    GET_PRODUCTS: 'GET_PRODUCTS',
    GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
    GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_ERROR: 'GET_PRODUCTS_ERROR',

    GET_PRODUCTS_PRICE: 'GET_PRODUCTS_PRICE',
    GET_PRODUCTS_PRICE_REQUEST: 'GET_PRODUCTS_PRICE_REQUEST',
    GET_PRODUCTS_PRICE_SUCCESS: 'GET_PRODUCTS_PRICE_SUCCESS',
    GET_PRODUCTS_PRICE_ERROR: 'GET_PRODUCTS_PRICE_ERROR',
    CLEAR_PRODUCTS_PRICE: 'CLEAR_PRODUCTS_PRICE',

    GET_INVOICES: 'GET_INVOICES',
    GET_INVOICES_REQUEST: 'GET_INVOICES_REQUEST',
    GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
    GET_INVOICES_ERROR: 'GET_INVOICES_ERROR',

    GET_CLIENTS: 'GET_CLIENTS',
    GET_CLIENTS_REQUEST: 'GET_CLIENTS_REQUEST',
    GET_CLIENTS_SUCCESS: 'GET_CLIENTS_SUCCESS',
    GET_CLIENTS_ERROR: 'GET_CLIENTS_ERROR',

    CREATE_CLIENT: 'CREATE_CLIENT',
    CREATE_CLIENT_REQUEST: 'CREATE_CLIENT_REQUEST',
    CREATE_CLIENT_SUCCESS: 'CREATE_CLIENT_SUCCESS',
    CREATE_CLIENT_ERROR: 'CREATE_CLIENT_ERROR',

    GENERATE_INVOICE: 'GENERATE_INVOICE',
    GENERATE_INVOICE_REQUEST: 'GENERATE_INVOICE_REQUEST',
    GENERATE_INVOICE_SUCCESS: 'GENERATE_INVOICE_SUCCESS',
    GENERATE_INVOICE_ERROR: 'GENERATE_INVOICE_ERROR',

    DOWNLOAD_INVOICE: 'DOWNLOAD_INVOICE',
    DOWNLOAD_INVOICE_REQUEST: 'DOWNLOAD_INVOICE_REQUEST',
    DOWNLOAD_INVOICE_SUCCESS: 'DOWNLOAD_INVOICE_SUCCESS',
    DOWNLOAD_INVOICE_ERROR: 'DOWNLOAD_INVOICE_ERROR',

    CASH_TRANSFER: 'CASH_TRANSFER',
    CASH_TRANSFER_REQUEST: 'CASH_TRANSFER_REQUEST',
    CASH_TRANSFER_SUCCESS: 'CASH_TRANSFER_SUCCESS',
    CASH_TRANSFER_ERROR: 'CASH_TRANSFER_ERROR',

    GET_COMPANY: 'GET_COMPANY',
    GET_COMPANY_REQUEST: 'GET_COMPANY_REQUEST',
    GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
    GET_COMPANY_ERROR: 'GET_COMPANY_ERROR',

    GET_WAREHOUSE: 'GET_WAREHOUSE',
    GET_WAREHOUSE_REQUEST: 'GET_WAREHOUSE_REQUEST',
    GET_WAREHOUSE_SUCCESS: 'GET_WAREHOUSE_SUCCESS',
    GET_WAREHOUSE_ERROR: 'GET_WAREHOUSE_ERROR',

    GET_CASH: 'GET_CASH',
    GET_CASH_REQUEST: 'GET_CASH_REQUEST',
    GET_CASH_SUCCESS: 'GET_CASH_SUCCESS',
    GET_CASH_ERROR: 'GET_CASH_ERROR',

    SET_ACTIVE_COMPANY: 'SET_ACTIVE_COMPANY',
    SET_ACTIVE_WAREHOUSE: 'SET_ACTIVE_WAREHOUSE',
    SET_ACTIVE_CASH: 'SET_ACTIVE_CASH',

    CLEAR_STATE: 'CLEAR_STATE',
    CLEAR_INVOICE_STATE: 'CLEAR_INVOICE_STATE',
    RESET_INVOICE: 'RESET_INVOICE',
};

export default salesConstants;