import NITController from '../services/nit.service'

export const actionTypes = {
    FIND_BY_NIT: 'FIND_BY_NIT',
    FIND_BY_NIT_REQUEST: 'FIND_BY_NIT_REQUEST',
    FIND_BY_NIT_SUCCESS: 'FIND_BY_NIT_SUCCESS',
    FIND_BY_NIT_ERROR: 'FIND_BY_NIT_ERROR',
};

const findByNitRequest = () => ({type: actionTypes.FIND_BY_NIT_REQUEST});
const findByNitSuccess = user => ({type: actionTypes.FIND_BY_NIT_SUCCESS, user,});
const findByNitError = error => ({type: actionTypes.FIND_BY_NIT_ERROR, error,});


export const findByNit = (nit) => async (dispatch) => {
    dispatch(findByNitRequest());
    try {
        const result = await NITController.findByNit(nit);
        dispatch(findByNitSuccess(result));
    } catch (error) {
        if (error.response.status === 404) {
            const result = {nit: nit, regime: 4};
            dispatch(findByNitSuccess(result));
        } else {
            dispatch(findByNitError(error.response.data));
        }
    }
};

export const clearNitSearch = () => dispatch => {
    dispatch(findByNitSuccess({name: '', taxRegime: 4}));
};