import {$http} from './http';

class ExpensesController {

    nullify = (value) =>  value !== null && value === '' ? null : value;


    getExpensesCategories = async () => {
        try {
            const result = await $http.get('/erp/inventory/vendor/app/categories');
            return result.data.data;
        } catch (error) {
            throw  error;
        }
    };

    saveExpense = async (vendorNIT, vendorName, series, number, date, total, category, serviceSale, invoice, vendorRegime) => {
        try {
            const result = await $http.post('/erp/expense/singleDocument',{vendorNIT, vendorName, series, number, date, total, category, serviceSale, invoice, vendorRegime});
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

}

export default new ExpensesController();