import {actionTypes} from '../actions/expenses.actions'


const initialState = {
    categories: [],
    saveResult: false,
};

const ExpensesReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GET_EXPENSES_CATEGORIES_REQUEST:
            return {
                ...state,
            };
        case actionTypes.GET_EXPENSES_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.value,
            };
        case actionTypes.SAVE_EXPENSE_REQUEST:
            return {
                ...state,
            };
        case actionTypes.SAVE_EXPENSE_SUCCESS:
            return {
                ...state,
                saveResult: action.value,
            };
        default:
            return state ? state : initialState;
    }

};

export default ExpensesReducer;