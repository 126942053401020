import React, {useState} from 'react';
import {Col, Grid, Row} from 'react-bootstrap';
import {useSelector, useDispatch} from "react-redux";
import Button from 'react-bootstrap-button-loader';
import iconCvColor from '../../media/icons/cv-color.svg';
import iconTestsColor from '../../media/icons/tests-color.svg';
import iconOffersColor from '../../media/icons/offers-color.svg';
import './Dashboard.css'
import {selectUserFirstName} from "../../selectors/user.selector";
import {actionTypes} from "../../actions/user.actions";
import {loadingSelector} from "../../selectors/loading.selector";
import {history} from "../../App";
import {Link} from "react-router-dom";
import {showToolbar} from "../../actions/dashboard.actions";

function Dashboard() {
    const dispatch = useDispatch();
    dispatch(showToolbar(true));
    const firstName = useSelector( state => selectUserFirstName(state));

    return  (
        <div>

            <Grid style={{marginBottom: 50}}>
                {/*<Row style={{textAlign: 'center'}}>*/}
                {/*    <img src={logo} alt="injob logo" width={215}/>*/}
                {/*</Row>*/}
                <Row style={{textAlign: 'center', marginTop: 10}}>
                    <h1 className={"blue montserrat f400 fs-32"}>¡ Hola {firstName}!</h1>
                </Row>
                <Row style={{textAlign: 'center', marginBottom: 35}}>
                    <Col xs={12} md={8} mdOffset={2}>
                        <h3 className={'open-sans fs-22'} style={{fontSize: 22, lineHeight: 1.5}}>Te damos la bienvenida al punto de ventas</h3>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3} style={{textAlign: 'center'}}>
                        {/*<div className={'round green'}>1</div>*/}
                        <img src={iconCvColor} alt="opcion cv" height={70} style={{marginBottom: 15}}/>
                        <h4 className={'blue montserrat f500 fs-16'}>VENTAS</h4>
                        <h6 className={'open-sans'}>Crear venta</h6>
                        <Button className={'custom-button'} onClick={ () => history.replace(`${process.env.PUBLIC_URL}/sales`)}>CREAR VENTA</Button>
                    </Col>
                    <Col xs={12} md={3} style={{textAlign: 'center'}}>
                        {/*<div className={'round green'}>2</div>*/}
                        <img src={iconTestsColor} alt="opcion cv" height={70} style={{marginBottom: 15}}/>
                        <h4 className={'blue montserrat f500 fs-16'}>FACTURAS</h4>
                        <h6 className={'open-sans'}>Listado de Facturas emitidas</h6>
                        <Button className={'custom-button'} onClick={ () => history.replace(`${process.env.PUBLIC_URL}/invoices`)}>IR A FACTURAS</Button>
                    </Col>
                    <Col xs={12} md={3} style={{textAlign: 'center'}}>
                        {/*<div className={'round '}>3</div>*/}
                        <img src={iconOffersColor} alt="opcion cv" height={70} style={{marginBottom: 15}}/>
                        <h4 className={'blue montserrat f500 fs-16'}>Configuracion</h4>
                        <h6 className={'open-sans'}>Configuracion de Punto de Ventas</h6>
                        <Button className={'custom-button'} onClick={ () => history.replace(`${process.env.PUBLIC_URL}/config`)}>Configuracion</Button>
                    </Col>
                    <Col xs={12} md={3} style={{textAlign: 'center'}}>
                        {/*<div className={'round '}>3</div>*/}
                        <img src={iconOffersColor} alt="opcion cv" height={70} style={{marginBottom: 15}}/>
                        <h4 className={'blue montserrat f500 fs-16'}>Cajas</h4>
                        <h6 className={'open-sans'}>Transferencia entre Cajas</h6>
                        <Button className={'custom-button'} onClick={ () => history.replace(`${process.env.PUBLIC_URL}/cash`)}>Configuracion</Button>
                    </Col>

                </Row>
            </Grid>
        </div>

    );
}

export default Dashboard;