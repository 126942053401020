import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loadingSelector} from "../../selectors/loading.selector";
import {singleErrorSelector} from "../../selectors/error.selector";
import {actionTypes, login} from "../../actions/user.actions";
import { getCompanies, getWarehouse, getCash } from "../../actions/product.actions";
import './login.css';
import Button from 'react-bootstrap-button-loader';

class LoginForm extends Component{

    state = {
        email: '',
        password: '',
    };

    login = (event, email, password) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.login(email, password);
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };


    render(){

        const {isLoginLoading, loginErrors} = this.props;
        const errors = (loginErrors.data) ? loginErrors.data : {email:"", password:""};
        const {email, password} = this.state;

        return(
            <form autoComplete={"off"}  onSubmit={(event) => this.login(event, email, password)} className={'login-form'}>
                <div className={"login-form-title"}>
                    <h3 className={"blue f400"}>Bienvenido</h3>
                    <h5 className={"open-sans"}>Ingresa tus datos para acceder</h5>
                </div>
                <div className={'input-wrapper'}>
                    <input
                        autoFocus
                        type="email"
                        name={"email"}
                        id={"email"}
                        value={email}
                        onChange={this.handleChange}
                        className={"input form-control " + (errors.email ? " has-error" : "")}
                        placeholder={"Correo Electrónico"}
                        required={""}
                    />
                    <span className="input-focus"/>
                    <span className={`input-symbol ${errors.email? 'has-error':''}`} >
							<i className="fas fa-at" aria-hidden="true"/>
						</span>
                    {errors.email ? <div className="input-error">{errors.email}</div> : <div/>}
                </div>
                <div className={'input-wrapper has-feedback'}>
                    <input
                        type={"password"}
                        name={"password"}
                        id={"password"}
                        value={password}
                        onChange={this.handleChange}
                        className={"input form-control" + (errors.password ? " has-error" : "")}
                        placeholder={"Contraseña"}
                        required={""}
                    />
                    <span className="input-focus"/>
                    <span className={`input-symbol ${errors.password? 'has-error':''}`}>
							<i className="fas fa-key" aria-hidden="true"/>
						</span>
                    {errors.password ? <div className="input-error">{loginErrors.data.password}</div> : <div/>}
                </div>
                <div className={'button-container'}>
                    <Button type="submit" className={"login-button"} loading={isLoginLoading} >
                        INICIAR SESION
                    </Button>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => ({
    isLoginLoading: loadingSelector([actionTypes.LOGIN])(state),
    loginErrors: singleErrorSelector([actionTypes.LOGIN])(state),

});

const mapDispatchToProps = dispatch => ({
    login: (email, password) => dispatch(login(email, password)),
    getCompanies: () => dispatch(getCompanies()),
    getWarehouse: () => dispatch(getWarehouse()),
    getCash: () => dispatch(getCash()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);