import React, { Component } from 'react';
import './Footer.css'

import { Row, Media, Label, Container, Col } from 'reactstrap';
import IninInline from '../../media/inin_logo_blanco_inline.svg';

export class Footer extends Component {
    render() {
        return (
            <div className="footer-fondo">
                <Container>
                    <Row>
                        <Col xs={12} md={9}>
                            <img style={{ height: "44px", width: "174px" }} src={IninInline} />
                        </Col>

                        <Col xs={12} md={3}>
                            <span style={{marginTop:'8px'}} className="ml-auto footer-marca-agua">Aviso Legal | Politica de Privacidad<br />Copyright © 2016 Inversiones Intelectuales</span>
                        </Col>

                        

                        
                    </Row>
                </Container>
            </div>
        )
    }
}