import salesConstants from '../constants/sales.constants';

const initialState = {
    products: [],
    productPrices: [],
    clients: [],
    invoice: {},
    availableCompanies: [],
    availableWarehouses: [],
    availableCash: [],
    company: {},
    warehouse: {},
    cash: {},
    resetInvoice: false,
    isLoadingClient: false,
    isLoadingCreateClient: false,
    isLoadingProduct: false,
    isLoadingProductPrices: false,
    isLoadingGenerateInvoice: false,
    isLoadingInvoices: false,
    isLoadingDownloadInvoice: false,
    isLoadingCashTransfer: false,
    isLoadingAvailableCompanies: false,
    isLoadingAvailableWarehouse: false,
    isLoadingAvailableCash: false,
    invoices: [],
};

const salesReducer = (state = initialState, action) => {
    switch (action.type) {

        case salesConstants.GET_PRODUCTS_REQUEST:
            return {
                ...state,
                isLoadingProduct: true,
            };
        case salesConstants.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.availableProducts,
                isLoadingProduct: false,
            };

        case salesConstants.GET_PRODUCTS_ERROR:
            return {
                ...state,
                isLoadingProduct: false,
            };

        case salesConstants.GET_CLIENTS_REQUEST:
            return {
                ...state,
                isLoadingClient: true,
            };
        case salesConstants.GET_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: action.clients,
                isLoadingClient: false,
            };

        case salesConstants.GET_CLIENTS_ERROR:
            return {
                ...state,
                isLoadingClient: false,
            };



        case salesConstants.GET_INVOICES_REQUEST:
            return {
                ...state,
                isLoadingInvoices: true,
            };
        case salesConstants.GET_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: action.invoices,
                isLoadingInvoices: false,
            };

        case salesConstants.GET_INVOICES_ERROR:
            return {
                ...state,
                isLoadingInvoices: false,
            };



        case salesConstants.CREATE_CLIENT_REQUEST:
            return {
                ...state,
                isLoadingCreateClient: true,
            };
        case salesConstants.CREATE_CLIENT_SUCCESS:
            return {
                ...state,
                isLoadingCreateClient: false,
            };

        case salesConstants.CREATE_CLIENT_ERROR:
            return {
                ...state,
                isLoadingCreateClient: false,
            };

        case salesConstants.GET_PRODUCTS_PRICE_REQUEST:
            return {
                ...state,
                isLoadingProductPrices: true,
            };
        case salesConstants.GET_PRODUCTS_PRICE_SUCCESS:
            return {
                ...state,
                productPrices: action.availablePrice,
                isLoadingProductPrices: false,
            };
        case salesConstants.GET_PRODUCTS_PRICE_ERROR:
            return {
                ...state,
                productPrices: [],
                isLoadingProductPrices: false,
            };
        case salesConstants.CLEAR_PRODUCTS_PRICE:
            return {
                ...state,
                productPrices: []
            };
        case salesConstants.GENERATE_INVOICE_REQUEST:
            return {
                ...state,
                isLoadingGenerateInvoice: true,
            };
        case salesConstants.GENERATE_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.invoice,
                isLoadingGenerateInvoice: false,
            };
        case salesConstants.GENERATE_INVOICE_ERROR:
            return {
                ...state,
                isLoadingGenerateInvoice: false,
            };
        case salesConstants.DOWNLOAD_INVOICE_REQUEST:
            return {
                ...state,
                isLoadingDownloadInvoice: true,
            };
        case salesConstants.DOWNLOAD_INVOICE_SUCCESS:
            return {
                ...state,
                isLoadingDownloadInvoice: false,
            };
        case salesConstants.DOWNLOAD_INVOICE_ERROR:
            return {
                ...state,
                isLoadingDownloadInvoice: false,
            };
        case salesConstants.CASH_TRANSFER_REQUEST:
            return {
                ...state,
                isLoadingCashTransfer: true,
            };
        case salesConstants.CASH_TRANSFER_SUCCESS:
            return {
                ...state,
                isLoadingCashTransfer: false,
            };
        case salesConstants.CASH_TRANSFER_ERROR:
            return {
                ...state,
                isLoadingCashTransfer: false,
            };
        case salesConstants.GET_COMPANY_REQUEST:
            return {
                ...state,
                isLoadingAvailableCompanies: true,
            };
        case salesConstants.GET_COMPANY_SUCCESS:
            return {
                ...state,
                availableCompanies: action.availableCompanies,
                isLoadingAvailableCompanies: false,
            };
        case salesConstants.GET_COMPANY_ERROR:
            return {
                ...state,
                isLoadingAvailableCompanies: false,
            };
        case salesConstants.GET_WAREHOUSE_REQUEST:
            return {
                ...state,
                isLoadingAvailableWarehouse: true,
            };
        case salesConstants.GET_WAREHOUSE_SUCCESS:
            return {
                ...state,
                availableWarehouses: action.availableWarehouses,
                isLoadingAvailableWarehouse: false,
            };
        case salesConstants.GET_WAREHOUSE_ERROR:
            return {
                ...state,
                isLoadingAvailableWarehouse: false,
            };
        case salesConstants.GET_CASH_REQUEST:
            return {
                ...state,
                isLoadingAvailableCash: true,
            };
        case salesConstants.GET_CASH_SUCCESS:
            return {
                ...state,
                availableCash: action.availableCash,
                isLoadingAvailableCash: false,
            };
        case salesConstants.GET_CASH_ERROR:
            return {
                ...state,
                isLoadingAvailableCash: false,
            };
        case salesConstants.SET_ACTIVE_COMPANY:
            return {
                ...state,
                company: action.company
            };
        case salesConstants.SET_ACTIVE_WAREHOUSE:
            return {
                ...state,
                warehouse: action.warehouse
            };
        case salesConstants.SET_ACTIVE_CASH:
            return {
                ...state,
                cash: action.cash
            };
        case salesConstants.CLEAR_STATE:
            const {company, warehouse, cash} = state;
            return {...initialState, company: company, warehouse: warehouse, cash: cash, resetInvoice: true};
        case salesConstants.CLEAR_INVOICE_STATE:
            return initialState;
        case salesConstants.RESET_INVOICE:
            return {
                ...state,
                resetInvoice: false
            };
        default:
            return state;
    }
};

export default salesReducer;