import React, {Component} from 'react';
import './CustomModal.css'
import {Modal} from "react-bootstrap";

export default class CustomModal extends Component {

    render() {

        const {title, type} = this.props;
        const icon = this.props.type === 'info' ? 'info' : this.props.type === 'success' ? 'check' : this.props.type === 'warning' ? 'exclamation-triangle' : this.props.type === 'question' ? 'question' : this.props.type === 'error' ? 'times' : '';
        return (
            <Modal show={this.props.show} dialogClassName={'custom-modal ' + type} backdrop={this.props.backdrop? this.props.backdrop : 'static'} onHide={this.props.onHide}>
                <Modal.Header>
                    <i className={'modal-icon fas fa-'+icon}/>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
                {
                    this.props.footer &&
                    <Modal.Footer>
                        {this.props.footer}
                    </Modal.Footer>
                }
            </Modal>
        );
    }
}