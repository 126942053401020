import {$http} from './http';

export class UsersController {

    nullify = (value) =>  value !== null && value === '' ? null : value;
    toBoolean = (value) => (value === 'true');

    login = async (email, password) => {
        try {
            const result = await $http.post('/user/login', {email, password});
            $http.defaults.headers.common['Authorization'] = 'Bearer ' + result.data.data.token;
            // $http.defaults.headers.common['ININ-Company'] = '618';
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    register = async (country, mail, pass) => {
        try {
            mail = this.nullify(mail);
            pass = this.nullify(pass);
            const result = await $http.post('/user/register', {mail, pass, pais:country});
            return result.data.data;
        } catch (error) {
            throw error;
        }

    };

    recoverPassword = async (email) => {
        try {
            email = this.nullify(email);
            const result = await $http.post(`/user/password/recover`, {email});          
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    changePassword = async (token, pass1, pass2) => {
        try {
            pass1 = this.nullify(pass1);
            pass2 = this.nullify(pass2);
            const result = await $http.post(`/user/password/change`, {token, pass1, pass2});
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    recoverToken = async (token) => {
        try {
            token = this.nullify(token);
            const result = await $http.get(`/user/password/recover/${token}` );
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    validateToken = async (token) => {
        try {           
            token = this.nullify(token);            
            const result = await $http.get(`/user/register/confirm/${token}` );
            $http.defaults.headers.common['Authorization'] = 'Bearer ' + result.data.data.token;
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    resendEmail = async (email) => {
        try {
            email = this.nullify(email);
            const result = await $http.post(`/user/register/confirm/resend`, {email});          
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };


    acceptTerms = async () => {
        try {
            const result = await $http.post('cv/terms','');
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    acceptedTerms = async () => {
        try {
            const result = await $http.get('cv/terms');
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    getExtraData = async ( ) => {
        try  {
            const result = await $http.get('/user/edit/data');
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    saveExtraData = async (data) => {
        try {
            await $http.post('/user/edit', data);
            return data;
        } catch (error) {
            throw error;
        }
    };

    refreshSession = async (token) => {
        try {
            if(token) $http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const result = await $http.get('/session/refresh/20');
            return result.data.data;
        } catch (error) {
            throw error;
        }
    };

    logout = () => {
        $http.defaults.headers.common['Authorization'] = '';
        return true;
    };

    getCountries = async () => {
        try {
            const result = await $http.get('/country');
            return result.data.data;
        } catch (error) {
            throw error;
        }
    }


}

export default new UsersController();