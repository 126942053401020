import {actionTypes} from '../actions/incomes.actions'


const initialState = {
    saveResult: false,
};

const IncomesReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SAVE_INCOME_REQUEST:
            return {
                ...state,
            };
        case actionTypes.SAVE_INCOME_SUCCESS:
            return {
                ...state,
                saveResult: action.value,
            };
        default:
            return state? state: initialState;
    }

};

export default IncomesReducer;