import React, {Component} from 'react';
import {connect} from 'react-redux';
import {selectToken, selectUserImage} from "../../selectors/user.selector";
import MenuToogle from './MenuToogle';
import {expandMenu, showToolbar} from "../../actions/dashboard.actions";
import {selectMenuCollapsed, selectShowToolbar} from "../../selectors/dashboard.selector";
import logo from '../../media/logos/inJobColor.svg';
import './Toolbar.css'
import  {history} from "../../App";
import {Link} from "react-router-dom";
import {logout} from "../../actions/user.actions";
import {Col, Grid, Row} from "react-bootstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {selectActiveCash, selectActiveCompany, selectActiveWarehouse} from "../../selectors/sales.selector";
import isEmpty from "lodash/isEmpty";


class ToolBar extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    logout = () => this.props.logout();

    async goToHome() {
        this.props.showToolbar(false);
        history.replace(`${process.env.PUBLIC_URL}/`);
    }

    goToPsychometricTests = () => {
        this.props.showToolbar(true);
        history.replace(`${process.env.PUBLIC_URL}/pruebas`);
    };

    goToJobs = () => {
        this.props.showToolbar(true);
        history.replace(`${process.env.PUBLIC_URL}/empleos`);
    };

    goToCV = () => {
        if (this.props.acceptedTerms) {
            this.props.showToolbar(true);
            history.replace(`${process.env.PUBLIC_URL}/cv`);
        } else {
            this.setState({showAcceptTermsModal: true});
        }
    };


    expandMenu = val => this.props.expandMenu(val);

    render() {
        const {token, collapsed, toolbar, image, activeCompany, activeWarehouse, activeCash} = this.props;
        return (
            token !== null  && toolbar &&
            <nav>
                <div className={'toolbar-container'}>
                    <div>
                        {!isEmpty(activeCompany) ?
                            <Row>
                                <Col xs={9} md={9}>
                                    <h5 className={'blue montserrat f500 fs-16'}> {activeCompany.companyName}</h5>
                                </Col>
                                <Col xs={3} md={3}>
                                    <h5 className={'blue montserrat f500 fs-16'}> {activeCompany.alphanumericId}</h5>
                                </Col>
                            </Row> :
                            null
                        }
                    </div>

                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>

                        <DropdownToggle tag="span">
                            {/*<MenuToogle onToogle={(e) => { this.logout()  }} />*/}
                            <Row>
                                <Col xs={6} md={6}>
                                    <img className={'rounded-image'} src={"https://s3.amazonaws.com/rrhh-images/user/image/"+image} width={40} style={{marginTop: '5px'}}/>
                                </Col>
                                <Col xs={6} md={6}>
                                    <MenuToogle />
                                </Col>
                            </Row>
                        </DropdownToggle>

                        <DropdownMenu>
                            <DropdownItem tag="div" style={{backgroundColor: '#067BC2'}}>

                                    <Row>
                                        <Link to={`${process.env.PUBLIC_URL}/`}>
                                        <Col xs={2} md={2}>
                                            <i  className={"fas fa-home"} style={{color: '#F9F9F9', marginTop: '8px', marginLeft: '8px'}}></i>
                                        </Col>
                                        <Col xs={10} md={10}>
                                            <h5 style={{color: '#F9F9F9'}}> Inicio</h5>
                                        </Col>
                                        </Link>
                                    </Row>

                            </DropdownItem>

                            <DropdownItem tag="div" style={{backgroundColor: '#69AFDA'}}>
                                <Row>
                                    <Link to={`${process.env.PUBLIC_URL}/config`}>
                                    <Col xs={2} md={2}>
                                        <i  className={"fas fa-cogs"} style={{color: '#F9F9F9', marginTop: '8px', marginLeft: '8px'}}></i>
                                    </Col>
                                    <Col xs={10} md={10}>
                                        <h5 style={{color: '#F9F9F9'}}>Configuracion</h5>
                                    </Col>
                                    </Link>
                                </Row>
                            </DropdownItem>

                            <DropdownItem divider />

                            <DropdownItem tag="div">
                                <Row>
                                    <Link to={`${process.env.PUBLIC_URL}/sales`} style={{color: '#43425D'}}>
                                        <Col xs={2} md={2}>
                                            <i  className={"fas fa-file-invoice-dollar"} style={{marginTop: '8px', marginLeft: '8px'}}></i>
                                        </Col>
                                        <Col xs={10} md={10}>
                                            <h5>Generar Factura</h5>
                                        </Col>
                                    </Link>
                                </Row>
                            </DropdownItem>

                            <DropdownItem divider />

                            {/*<DropdownItem disabled tag="div">*/}
                            {/*    <Row>*/}
                            {/*        <Col xs={2} md={2}>*/}
                            {/*            <i  className={"fas fa-users"} style={{marginTop: '8px', marginLeft: '8px'}}></i>*/}
                            {/*        </Col>*/}
                            {/*        <Col xs={10} md={10}>*/}
                            {/*            <h5>Ofertas laborales</h5>*/}
                            {/*        </Col>*/}
                            {/*    </Row>*/}
                            {/*</DropdownItem>*/}

                            {/*<DropdownItem divider />*/}

                            {/*<DropdownItem disabled tag="div">*/}
                            {/*    <Row>*/}
                            {/*        <Col xs={2} md={2}>*/}
                            {/*            <i  className={"fas fa-bell"} style={{marginTop: '8px', marginLeft: '8px'}}></i>*/}
                            {/*        </Col>*/}
                            {/*        <Col xs={10} md={10}>*/}
                            {/*            <h5>Notificaciones</h5>*/}
                            {/*        </Col>*/}
                            {/*    </Row>*/}
                            {/*</DropdownItem>*/}

                            <DropdownItem divider />

                            <DropdownItem disabled tag="div">
                                <Row>
                                    <a style={{cursor:'pointer',color: '#43425D'}} onClick={(e) => { this.logout()  }}>
                                        <Col xs={2} md={2}>
                                            <i  className={"fas fa-sign-out-alt"} style={{marginTop: '8px', marginLeft: '8px'}}></i>
                                        </Col>
                                        <Col xs={10} md={10}>
                                            <h5>Salir</h5>
                                        </Col>
                                    </a>
                                </Row>
                            </DropdownItem>

                        </DropdownMenu>
                    </Dropdown>

                </div>
            </nav>
        );
    }
}


const mapStateToProps = (state) => ({
    token: selectToken(state),
    collapsed: selectMenuCollapsed(state),
    toolbar: selectShowToolbar(state),
    image: selectUserImage(state),
    activeCompany: selectActiveCompany(state),
    activeWarehouse: selectActiveWarehouse(state),
    activeCash: selectActiveCash(state),
});

const mapDispatchToProps = dispatch => ({
    expandMenu: (val) => dispatch(expandMenu(val)),
    showToolbar: (val) => dispatch(showToolbar(val)),
    logout: () => dispatch(logout()),
});

const connectedToolBar = connect(mapStateToProps, mapDispatchToProps)(ToolBar);
export {connectedToolBar as ToolBar};



