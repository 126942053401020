import {actionTypes} from '../actions/charts.actions'


const initialState = {
    incomesVsExpenses: {},
    expenses:[],
};

const ChartsDataReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GET_INCOME_VS_EXPENSES_REQUEST:
            return {
                ...state,
            };
        case actionTypes.GET_INCOME_VS_EXPENSES_SUCCESS:
            return {
                ...state,
                incomesVsExpenses: action.data,
            };
        case actionTypes.GET_EXPENSES_REQUEST:
            return {
                ...state,
            };
        case actionTypes.GET_EXPENSES_SUCCESS:
            return {
                ...state,
                expenses: action.data,
            };
        default:
            return state ? state : initialState;
    }

};

export default ChartsDataReducer;