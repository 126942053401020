import React, {Component} from 'react';
import {connect} from 'react-redux';
import Login from '../../content/Login/Login'
import {selectToken} from "../../selectors/user.selector";
import './SecuredContent.css'

class SecuredContent extends Component {



    render() {
        const {token} = this.props;
        if (token!== null) {
            return (
                <div role="main" style={{flex:1}} className={'secured-content'}>
                    {this.props.children}
                </div>
            )
        } else {
            return <Login/>
        }
    }
}

const mapStateToProps = (state) => ({
    token: selectToken(state),
});

const connectedSecuredContent = connect(mapStateToProps)(SecuredContent);
export {connectedSecuredContent as SecuredContent};
