import * as React from 'react';
import {connect} from 'react-redux';
import Button from 'react-bootstrap-button-loader';
import {Link} from 'react-router-dom';
import {singleErrorSelector} from "../../selectors/error.selector";
import {loadingSelector} from "../../selectors/loading.selector";
import {actionTypes, recoverPassword} from "../../actions/user.actions";
import LoginContainer from '../Login/LoginContainer';
import logo from '../Login/Logo.png';
import '../Login/login.css'

class PasswordRecovery extends React.Component {

    state = {
        email: ''      
    };

    recoverPassword = (event, email) => {
        event.preventDefault();
        event.stopPropagation();      
        this.props.recoverPassword(email);
    };
    
    handleChange = (event) => {
        const {name, value} = event.target;                          
        this.setState({[name]: value});
    };

    render() {        
        const {isRecoverPasswordLoading, recoverPasswordErrors} = this.props;     
        const {email} = this.state;               
        const errors = (recoverPasswordErrors.data) ? recoverPasswordErrors.data : {email:""};

        return (
            <LoginContainer>
                <div className={'tab-content'}>
                    <div className={"login-form-title"}>
                        <h3 className={"blue f400"}>RECUPERAR CONTRASEÑA</h3>
                        <h5 className={"open-sans"}>Ingresa tu correo y te enviaremos un link para que cambies tu contraseña</h5>
                    </div>
                    <form autoComplete={"off"}  onSubmit={(event) => this.recoverPassword(event, email)} className={'login-form'}>

                        <div className={'input-wrapper'}>
                            <input
                                autoFocus
                                type="email"
                                name={"email"}
                                id={"email"}
                                value={email}
                                onChange={this.handleChange}
                                className={"input form-control " + (errors.email ? " has-error" : "")}
                                placeholder={"Correo Electrónico"}
                                required={""}
                            />
                            <span className="input-focus"/>
                            <span className={`input-symbol ${errors.email? 'has-error':''}`}>
							<i className="fas fa-at" aria-hidden="true"/>
						</span>
                            {errors.email ? <div className="input-error">{errors.email}</div> : <div/>}
                        </div>
                        <div className={'button-container'}>
                            <Button type="submit" className={"login-button"} loading={isRecoverPasswordLoading} >
                                RESTAURAR CONTRASEÑA
                            </Button>
                            <Link to={`${process.env.PUBLIC_URL}/login`} className={"txt2"} style={{marginTop: 25, cursor:'pointer'}}>
                                <span className={'blue'}>VOLVER A INICIO DE SESION</span>
                            </Link>
                        </div>
                    </form>
                </div>
            </LoginContainer>
        );

    }

}

const mapStateToProps = state => ({
    isRecoverPasswordLoading: loadingSelector([actionTypes.RECOVER_PASSWORD])(state),   
    recoverPasswordErrors: singleErrorSelector([actionTypes.RECOVER_PASSWORD])(state),
});

const mapDispatchToProps = dispatch => ({
    recoverPassword: (email) => dispatch(recoverPassword(email))
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery);
