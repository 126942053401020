import {actionTypes} from '../actions/nit.actions'


const initialState = {
    name: '',
    taxRegime: 4,
};

const NITReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FIND_BY_NIT_REQUEST:
            return {
                ...state,
                name: '',
                taxRegime: 4
            };
        case actionTypes.FIND_BY_NIT_SUCCESS:
            return {
                ...state,
                name: action.user.name,
                taxRegime: action.user.taxRegime,
            };
        default:
            return state? state: initialState;
    }

};

export default NITReducer;