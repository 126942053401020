import PaymentsController from '../services/payments.service'

export const actionTypes = {

    GET_SERVICE_STATUS: 'GET_SERVICE_STATUS',
    GET_SERVICE_STATUS_REQUEST: 'GET_SERVICE_STATUS_REQUEST',
    GET_SERVICE_STATUS_SUCCESS: 'GET_SERVICE_STATUS_SUCCESS',
    GET_SERVICE_STATUS_ERROR: 'GET_SERVICE_STATUS_ERROR',

};

const getServiceStatusRequest = () => ({type: actionTypes.GET_SERVICE_STATUS_REQUEST});
const getServiceStatusSuccess = (data) => ({type: actionTypes.GET_SERVICE_STATUS_SUCCESS, data});
const getServiceStatusError = (error) => ({type: actionTypes.GET_SERVICE_STATUS_ERROR});


export const getServiceStatus = (company, year, month) => async (dispatch) => {
    dispatch(getServiceStatusRequest());
    try {
        const data = await PaymentsController.getServiceStatus();
        dispatch(getServiceStatusSuccess(data));
    } catch (error) {
        console.log(error);
        dispatch(getServiceStatusError(error.response.data));
    }
};