import * as React from 'react';
import {connect} from 'react-redux';
import Button from 'react-bootstrap-button-loader';
import {Link} from 'react-router-dom';
import {singleErrorSelector} from "../../selectors/error.selector";
import {loadingSelector} from "../../selectors/loading.selector";
import {actionTypes, resendEmail} from "../../actions/user.actions";
import LoginContainer from '../Login/LoginContainer';
import logo from '../Login/Logo.png';

class ResendEmail extends React.Component {

    state = {
        email: ''      
    };

    resendEmail = (event, email) => {
        event.preventDefault();
        event.stopPropagation();      
        this.props.resendEmail(email);
    };
    
    handleChange = (event) => {
        const {name, value} = event.target;                          
        this.setState({[name]: value});
    };

    render() {        
        const {isResendEmailLoading, resendEmailErrors} = this.props;     
        const {email} = this.state;               
        const errors = (resendEmailErrors.data) ? resendEmailErrors.data : {email:""};

        return (
            <LoginContainer>
                <div className={'tab-content'}>
                    <div className={"login-form-title"} >
                        <h3 className={"blue f400"}>RE-ENVIAR CORREO DE CONFIRMACIÓN</h3>
                        <h5 className={'open-sans'}>Ingresa tu correo y te enviaremos un link para confirmar tu cuenta</h5>
                    </div>
                    <form autoComplete={"off"}  onSubmit={(event) => this.resendEmail(event, email)}>
                        <div className={'input-wrapper'}>
                            <input
                                autoFocus
                                type="email"
                                name={"email"}
                                id={"email"}
                                value={email}
                                onChange={this.handleChange}
                                className={"input form-control " + (errors.email ? " has-error" : "")}
                                placeholder={"Correo Electrónico"}
                                required={""}
                            />
                            <span className="input-focus"/>
                            <span className={`input-symbol ${errors.email? 'has-error':''}`}>
							<i className="fas fa-at" aria-hidden="true"/>
						</span>
                            {errors.email ? <div className="input-error">{errors.email}</div> : <div/>}
                        </div>
                        <div className={'button-container'}>
                            <Button type="submit" className={"login-button"} loading={isResendEmailLoading} >
                                REENVIAR CORREO
                            </Button>
                            <Link to={`${process.env.PUBLIC_URL}/login`} className={"txt2"} style={{marginTop: 25, cursor:'pointer'}}>
                                <span className={'blue'}>VOLVER A INICIO DE SESION</span>
                            </Link>
                        </div>
                    </form>
                </div>

            </LoginContainer>
        );

    }

}

const mapStateToProps = state => ({
    isResendEmailLoading: loadingSelector([actionTypes.RESEND_EMAIL])(state),   
    resendEmailErrors: singleErrorSelector([actionTypes.RESEND_EMAIL])(state),
});

const mapDispatchToProps = dispatch => ({
    resendEmail: (email) => dispatch(resendEmail(email))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResendEmail);
