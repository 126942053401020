import {combineReducers} from "redux";
import user from "./user.reducer";
import loading from './loading.reducer';
import error from './error.reducer';
import {reducer as notifications} from 'react-notification-system-redux';
import dashboard from './dashboard.reducer'
import charts from './charts.reducer'
import declarations from './declarations.reducer'
import payments from './payments.reducer'
import nit from './nit.reducer'
import incomes from './incomes.reducer'
import expenses from './expenses.reducer';
import reports from './reports.reducer'
import cvReducer  from './cv.reducer';
import psychometricTestsReducer  from './psychometricTests.reducer';
import salesReducer  from './sales.reducer';

export const rootReducer = combineReducers({
    loading,
    error,
    user,
    dashboard,
    charts,
    declarations,
    payments,
    nit,
    incomes,
    expenses,
    reports,
    // cvReducer,
    // psychometricTestsReducer,
    salesReducer,
    notifications
});