import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import LoginContainer from './LoginContainer';
import './login.css';
import {selectToken} from "../../selectors/user.selector";
import {Tab, Tabs} from "react-bootstrap";
import LoginForm from "./LoginForm";
import {history} from "../../App";

class Login extends Component {


    componentDidUpdate() {
        const {token} = this.props;
        if (token !== null) {
            this.props.history.replace(`${process.env.PUBLIC_URL}/`);
        }
    }

    getLoginFooter = () => {
        return (
            <div className={"login-footer"}>
                <Link to={`${process.env.PUBLIC_URL}/recuperar-clave`} className={"txt1"} style={{cursor: 'pointer'}}>
                    ¿Olvidaste tu contraseña?
                </Link>
                <br/><br/>
                <Link to={`${process.env.PUBLIC_URL}/reenviar-correo`} className={"txt2"} style={{cursor: 'pointer', marginTop: 10}}>
                    <span className={'blue'}>¿No llegó tu email de confirmación de cuenta?</span>
                </Link>
            </div>
        );
    };

    toRegister = (key) => {
        if(key ===2)  history.replace(`${process.env.PUBLIC_URL}/registro`)
    };

    render() {
        return (
                <LoginContainer footer={this.getLoginFooter()}>
                    <Tabs defaultActiveKey={1} onSelect={this.toRegister} animation={false} id={'login-tabs'}>
                        <Tab eventKey={1} title="INGRESAR">
                            <LoginForm/>
                        </Tab>
                        <Tab eventKey={2} title="REGISTRARME">
                        </Tab>
                    </Tabs>
                </LoginContainer>
        );
    }
}

const mapStateToProps = state => ({
    token: selectToken(state),
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

