import ChartsController from '../services/charts.service'

export const actionTypes = {

    GET_INCOME_VS_EXPENSES: 'GET_INCOME_VS_EXPENSES',
    GET_INCOME_VS_EXPENSES_REQUEST: 'GET_INCOME_VS_EXPENSES_REQUEST',
    GET_INCOME_VS_EXPENSES_SUCCESS: 'GET_INCOME_VS_EXPENSES_SUCCESS',
    GET_INCOME_VS_EXPENSES_ERROR: 'GET_INCOME_VS_EXPENSES_ERROR',

    GET_EXPENSES: 'GET_EXPENSES',
    GET_EXPENSES_REQUEST: 'GET_EXPENSES_REQUEST',
    GET_EXPENSES_SUCCESS: 'GET_EXPENSES_SUCCESS',
    GET_EXPENSES_ERROR: 'GET_EXPENSES_ERROR',

};

const getIncomeVsExpensesRequest = () => ({type: actionTypes.GET_INCOME_VS_EXPENSES_REQUEST});
const getIncomeVsExpensesSuccess = (data) => ({type: actionTypes.GET_INCOME_VS_EXPENSES_SUCCESS, data});
const getIncomeVsExpensesError = (error) => ({type: actionTypes.GET_INCOME_VS_EXPENSES_ERROR});

const getExpensesRequest = () => ({type: actionTypes.GET_EXPENSES_REQUEST});
const getExpensesSuccess = (data) => ({type: actionTypes.GET_EXPENSES_SUCCESS, data});
const getExpensesError = (error) => ({type: actionTypes.GET_EXPENSES_ERROR});

export const getIncomeVsExpensesData = (company, year) => async (dispatch) => {
    dispatch(getIncomeVsExpensesRequest());
    try {
        const data = await ChartsController.getIncomeVsExpensesData(company, year);
        dispatch(getIncomeVsExpensesSuccess(data));
    } catch (error) {
        console.log(error);
        dispatch(getIncomeVsExpensesError(error.response.data));
    }
};

export const getExpensesData = (company, year, month) => async (dispatch) => {
    dispatch(getExpensesRequest());
    try {
        const data = await ChartsController.getExpensesData(company, year, month);
        dispatch(getExpensesSuccess(data));
    } catch (error) {
        dispatch(getExpensesError(error.response.data));
    }
};