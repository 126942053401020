import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import {SecuredContent} from '../components/SecuredContent/SecuredContent'
import Login from './Login/Login';
import Registration from './Registration/Registration';
import Dashboard from './Dashboard/Dashboard';
import PasswordRecovery from './PasswordRecovery/PasswordRecovery';
import PasswordChange from './PasswordChange/PasswordChange';
import ResendEmail from './ResendEmail/ResendEmail';
import EmailConfirmation from './EmailConfirmation/EmailConfirmation'
import './Content.css';
import Backdrop from "./Backdrop";
import NotFoundComponent from "./404/404";
import Sales from './Sales/Sales';
import UserConfiguration from "./Configuration/UserConfiguration";
import Invoices from "./Invoices/Invoices";
import CashTransfer from "./CashTransfer/CashTransfer";

class Content extends Component {

    render() {
        return (
            <Switch>

                <Route path={`${process.env.PUBLIC_URL}/login`} component={Login}/>
                <Route path={`${process.env.PUBLIC_URL}/registro`} component={Registration}/>
                <Route path={`${process.env.PUBLIC_URL}/recuperar-clave`} component={PasswordRecovery}/>
                <Route path={`${process.env.PUBLIC_URL}/cambiar-clave/:token`} component={PasswordChange}/>
                <Route path={`${process.env.PUBLIC_URL}/reenviar-correo`} component={ResendEmail}/>
                <Route path={`${process.env.PUBLIC_URL}/confirmar-cuenta/:token`} component={EmailConfirmation}/>



                <SecuredContent>
                    <div className={"container-fluid main-container"} onClick={this.collapseMenu}>
                        <Backdrop/>
                        <Switch>
                            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Dashboard}/>

                            <Route exact path={`${process.env.PUBLIC_URL}/sales`} component={Sales}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/config`} component={UserConfiguration}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/invoices`} component={Invoices}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/cash`} component={CashTransfer}/>
                            {/*<Route exact path={`${process.env.PUBLIC_URL}/pruebas/prueba/:id`} component={GeneralTestTemplate}/>*/}

                            <Route component={NotFoundComponent}/>
                        </Switch>

                    </div>
                </SecuredContent>
            </Switch>
        );
    }
}

export default Content;

