import React, {Component} from 'react';
import logo from '../../media/logos/inJobBlanco.svg'
import {Col} from "react-bootstrap";

class LoginContainer extends Component {

    render() {

        return (
            <div>
                <div className={"login-container"}>
                    <Col style={{textAlign:'center'}}>
                        {/*<img src={logo} alt="logo" className={"login-logo"}/>*/}
                        <div className={"login-wrapper"}>
                            {this.props.children}
                        </div>
                        {this.props.footer}
                    </Col>
                </div>
            </div>
        );

    }

}

export default LoginContainer;