import {$http} from "./http";
import axios from 'axios';
import {API_URL} from '../settings/app.settings'

class ReportsController {

    getPurchasesBookLink= async (start, end, type) => {
        try{
            const result = await  $http.get(`/erp/accounting/report/v2/purchases/${start}/${end}/${type}?link=true`);
            return result.data.data;
        } catch (error) {
            throw  error;
        }
    };

    getSalesBookLink= async (start, end, type) => {
        try{
            const result = await  $http.get(`/erp/accounting/report/v2/sales/${start}/${end}/${type}?link=true`);
            return result.data.data;

        } catch (error) {
            throw  error;
        }
    };

    getExecutiveReportLink= async (year, month) => {
        try{
            const result = await  $http.get(`/erp/accounting/report/v2/micontagt/${year}/${month}?link=true`);
            return result.data.data;

        } catch (error) {
            throw  error;
        }
    };

    downloadFile = async (link) =>{
        try{
            const result = await axios({ url: `${API_URL}/downloads/${link}`, method: 'GET', responseType: 'blob' });
            return result.data;
        } catch (error) {
            throw error;
        }
    };

    downloadInvoicePdfFile = async (invoiceId) =>{
        try{
            const result = await axios({ url: `${API_URL}/erp/sale/invoices/pdf/${invoiceId}`, method: 'GET', responseType: 'blob' });
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}

export default new ReportsController();