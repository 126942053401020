import {actionTypes} from '../actions/declarations.actions'

const today = new Date();
const initialState = {
    forms: [[],[],[],[],[],[],[],[],[],[],[],[]],
    closedMonthResponse: null,
    formPaidResponse: null,
    month: {year: today.getFullYear(), month: today.getMonth()+1},
    formDocument: '',
};

const DeclarationsReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GET_MONTHLY_FORMS:
            return {
                ...state,
            };
        case actionTypes.GET_MONTHLY_FORMS_REQUEST:
            return {
                ...state,
            };
        case actionTypes.GET_MONTHLY_FORMS_SUCCESS:
            return {
                ...state,
                forms: action.data,
            };
        case actionTypes.CLOSE_MONTH:
            return {
                ...state,
            };
        case actionTypes.CLOSE_MONTH_REQUEST:
            return {
                ...state,
            };
        case actionTypes.CLOSE_MONTH_SUCCESS:
            return {
                ...state,
                closedMonthResponse: action.data,
            };
        case actionTypes.SELECTED_DECLARATION_MONTH_REQUEST:
            return {
                ...state,
            };
        case actionTypes.SELECTED_DECLARATION_MONTH_SUCCESS:
            return {
                ...state,
                month: action.data,
            };
        case actionTypes.FORM_PAID_REQUEST:
            return {
                ...state,
            };
        case actionTypes.FORM_PAID_SUCCESS:
            return {
                ...state,
                formPaidResponse: action.data,
            };
        case actionTypes.DOWNLOAD_FORM_DOCUMENT_REQUEST:
            return {
                ...state,
            };
        case actionTypes.DOWNLOAD_FORM_DOCUMENT_SUCCESS:
            return {
                ...state,
                formDocument: action.data,
            };
        default:
            return state ? state : initialState;
    }

};

export default DeclarationsReducer;