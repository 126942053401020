import {$http} from "./http";

class IncomesController {

    saveIncome = async (nit, name, series, number, date, total, serviceSale, invoice)  =>{
        try {
            const result = await $http.post('/erp/sale/invoices', {nit, name, series, number, date, total, serviceSale,invoice} );
            return result.data.data;
        } catch (error) {
            throw error;
        }
    }
}

export default new IncomesController();