import React, {Component} from 'react';
import {expandMenu} from "../actions/dashboard.actions";
import {selectMenuCollapsed} from "../selectors/dashboard.selector";
import {connect} from "react-redux";

class Backdrop extends Component {

    collapseMenu = () => this.props.expandMenu(true);

    render() {
        const {collapsed} = this.props;
        const backdropClass = `backdrop ${!collapsed ? 'show' : ''}`;

        return (
            <div className={backdropClass} onClick={this.collapseMenu}/>
        );
    }
}

const mapStateToProps = (state) => ({
    collapsed: selectMenuCollapsed(state),
});

const mapDispatchToProps = dispatch => ({
    expandMenu: (val) => dispatch(expandMenu(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Backdrop);