import ExpensesController from '../services/expenses.service'
import {error as errorNotification, success as successNotification} from 'react-notification-system-redux';

export const actionTypes = {

    GET_EXPENSES_CATEGORIES: 'GET_EXPENSES_CATEGORIES',
    GET_EXPENSES_CATEGORIES_REQUEST: 'GET_EXPENSES_CATEGORIES_REQUEST',
    GET_EXPENSES_CATEGORIES_SUCCESS: 'GET_EXPENSES_CATEGORIES_SUCCESS',
    GET_EXPENSES_CATEGORIES_ERROR: 'GET_EXPENSES_CATEGORIES_ERROR',

    SAVE_EXPENSE: 'SAVE_EXPENSE',
    SAVE_EXPENSE_REQUEST: 'SAVE_EXPENSE_REQUEST',
    SAVE_EXPENSE_SUCCESS: 'SAVE_EXPENSE_SUCCESS',
    SAVE_EXPENSE_ERROR: 'SAVE_EXPENSE_ERROR',
};

const getExpensesCategoriesRequest = () => ({type: actionTypes.GET_EXPENSES_CATEGORIES_REQUEST});
const getExpensesCategoriesSuccess = value => ({type: actionTypes.GET_EXPENSES_CATEGORIES_SUCCESS, value});
const getExpensesCategoriesError = error => ({type: actionTypes.GET_EXPENSES_CATEGORIES_ERROR, error,});

const saveExpenseRequest = () => ({type: actionTypes.SAVE_EXPENSE_REQUEST});
const saveExpenseSuccess = value => ({type: actionTypes.SAVE_EXPENSE_SUCCESS, value});
const saveExpenseError = error => ({type: actionTypes.SAVE_EXPENSE_ERROR, error,});


const notification = (title, message, error) => dispatch => {
    if (!error) {
        dispatch(successNotification({title, message}));
    } else {
        dispatch(errorNotification({title, message}));
    }
    return true;
};


export const getExpensesCategories = () => async (dispatch) => {
    dispatch(getExpensesCategoriesRequest());
    try {
        const result = await ExpensesController.getExpensesCategories();
        dispatch(getExpensesCategoriesSuccess(result));
    } catch (error) {
        dispatch(getExpensesCategoriesError(error.response.data));
    }
};

export const saveExpense = (vendorNIT, vendorName, series, number, date, total, category, serviceSale, invoice, vendorRegime) => async (dispatch) => {
    dispatch(saveExpenseRequest());
    try {
        await  ExpensesController.saveExpense(vendorNIT, vendorName, series, number, date, total, category, serviceSale, invoice, vendorRegime);
        dispatch(saveExpenseSuccess(true));
        dispatch(notification("Gastos", "Documento guardado exitosamente."));
    } catch (error) {
        if (error.response.data) {
            dispatch(saveExpenseError(error.response.data));
            dispatch(notification("Ingresos", error.response.data.message, true));
        }
        dispatch(saveExpenseError({message: "Error, intente más tarde"}));
    }
};


export const clearSaveExpenseResult = () => dispatch => {
    dispatch( saveExpenseSuccess(false));
};