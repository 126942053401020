import {actionTypes} from "../actions/dashboard.actions";

const initialState = {
    collapsed: true,
    show: true,
    selected: 1,
    monthSelected: new Date().getMonth(),
    showToolbar: true,
};


const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MENU_SHOW:
            return {
                ...state,
                show: action.val
            };
        case actionTypes.SHOW_TOOLBAR:
            return {
                ...state,
                showToolbar: action.val
            };
        case actionTypes.MENU_EXPAND:
            return {
                ...state,
                collapsed: action.val,
            };
        case actionTypes.MENU_SELECTED:
            return {
                ...state,
                selected: action.val,
            };
        case actionTypes.MONTH_SELECTED:
            return {
                ...state,
                monthSelected: action.val,
            };
        default:
            return state;
    }
};

export default dashboardReducer