import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Table} from "react-bootstrap";
import { Panel, PanelGroup } from "react-bootstrap";
import Button from "react-bootstrap-button-loader";
import Select from 'react-select';
import {getAvailableProducts, getAvailablePrice, getAvailableClients, clearProductsPrices, generateInvoice, invoiceReseted, createClient} from "../../actions/product.actions";
import {selectAvailableProducts, selectAvailableProductPrice, selectAvailableClients, selectActiveCompany, selectActiveWarehouse, selectActiveCash, selectResetInvoice, isLoadingAvailableClients, isLoadingCreateClient, isLoadingProduct, isLoadingProductPrices, isLoadingGenerateInvoice, selectCreatedInvoice} from "../../selectors/sales.selector";
import {selectShowToolbar} from "../../selectors/dashboard.selector";
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import {Decimal} from 'decimal.js';
import CustomModal from "../../components/CustomModal/CustomModal";

import './Sales.css'
import {showToolbar} from "../../actions/dashboard.actions";
import {history} from "../../App";
import {Input} from "reactstrap";
import {downloadFile} from "../../actions/reports.actions";

function Sales() {
    const dispatch = useDispatch();

    const [quantityProduct, setQuantityProduct] = useState(0);
    const [downloadPDF, setDownloadPDF] = useState(false);
    const [gfaceByEmail, setGfaceByEmail] = useState(false);
    const [invoiceDescription, setInvoiceDescription] = useState(false);
    const [invoiceDescriptionValue, setInvoiceDescriptionValue] = useState("");
    const [gfaceEmail, setGfaceEmail] = useState("");
    const [priceProduct, setPriceProduct] = useState({label: '', value: '', price: '0'});
    const products = useSelector( state => selectAvailableProducts(state));
    const clients = useSelector(state => selectAvailableClients(state));
    const productPrices = useSelector( state => selectAvailableProductPrice(state));
    const [selectedProduct, setSelectedProduct] = useState({label: '', value: ''});
    const [selectedClient, setSelectedClient] = useState({label: '', value: '', name: ''});
    const [invoiceProducts, setInvoiceProducts] = useState([]);
    const [activeKey, setActiveKey] = useState(1);
    const isToolBarSelected = useSelector(selectShowToolbar);
    const activeCompany = useSelector(selectActiveCompany);
    const activeWarehouse = useSelector(selectActiveWarehouse);
    const activeCash = useSelector(selectActiveCash);
    const resetInvoice = useSelector(selectResetInvoice);
    const isLoadingClients = useSelector(isLoadingAvailableClients);
    const isLoadingProducts = useSelector(isLoadingProduct);
    const isLoadingProductPrice = useSelector(isLoadingProductPrices);
    const createdInvoice = useSelector(selectCreatedInvoice);
    const isLoadingGenerateInvoices = useSelector(isLoadingGenerateInvoice);
    const [invoiceRequest, setInvoiceRequest] = useState({});
    const warehouseId = activeWarehouse.id;
    const cashId = activeCash.id;
    const description = '';
    const discount = '0';
    const gface = true;
    // const gfaceByEmail = false;
    const gfaceDownload = downloadPDF;
    // const gfaceEmail = null;
    const invoiceVal = true;
    const normalDownload = false;

    //Create Client
    const [newClientNIT, setNewClientNIT] = useState("");
    const [newClientName, setNewClientName] = useState("");
    const [newClientCode, setNewClientCode] = useState("");
    const [newClientAddress, setNewClientAddress] = useState("");

    const [newClientContactName, setNewClientContactName] = useState("");
    const [newClientContactMail, setNewClientContactMail] = useState("");
    const [newClientContactPhone, setNewClientContactPhone] = useState("");
    const [newClientContactCellphone, setNewClientContactCellphone] = useState("");
    const [createClientFlag, setCreateClientFlag] = useState(false);

    //Payments
    const [cashAmount, setCashAmount] = useState(0);
    const [creditAmount, setCreditAmount] = useState(0);
    const [creditCardAmount, setCreditCardAmount] = useState({amount:0, reference: '', authorization: ''});

    const resetState = () => {

        dispatch(invoiceReseted());
        setQuantityProduct(0);
        setDownloadPDF(false);
        setPriceProduct({label: '', value: '', price: '0'});
        setSelectedProduct({label: '', value: ''});
        setSelectedClient({label: '', value: '', name: ''});
        setInvoiceProducts([]);
        setActiveKey(1);
        setInvoiceRequest({});
        setCashAmount(0);
        setCreditCardAmount({amount:0, reference: '', authorization: ''});
        setCreditAmount(0);
    };


    useEffect(() => {
        if(!isToolBarSelected)
        {
            dispatch(showToolbar(true));
        }
        if(isEmpty(activeCompany) || isEmpty(activeWarehouse) || isEmpty(activeCash))
        {
            history.replace(`${process.env.PUBLIC_URL}/config`);
        }
        // if(resetInvoice)
        // {
        //     resetState();
        //     dispatch(invoiceReseted);
        // }
    });

    const fetchProduct = (e) => {
        dispatch(getAvailableProducts(e));
    };

    const fetchClient = (clue) => {
        dispatch(getAvailableClients(clue));
    };

    const getProductPrice = (productId) => {
        dispatch(clearProductsPrices);
        dispatch(getAvailablePrice(productId));
        setPriceProduct({label: '', value: ''});
    };

    const filterOptions = (option, input) => {
        const {data} = option;
        const name = data.name || '';
        const barCode = data.barCode || '';
        const code = data.code || '';
        let userInput = input || '';

        if (input) {
            userInput = userInput.toString();
            return name.toLowerCase().includes(userInput.toLowerCase()) || barCode.toLowerCase().includes(userInput.toLowerCase()) || code.toLowerCase().includes(userInput.toLowerCase());
        }
        return false;
    };

    const filterClientOptions = (option, input) => {
        const {data} = option;
        const name = data.name || '';
        const nit = data.nit || '';
        let userInput = input || '';

        if (input) {
            userInput = userInput.toString();
            return name.toLowerCase().includes(userInput.toLowerCase()) || nit.toLowerCase().includes(userInput.toLowerCase());
        }
        return false;
    };

    const handleChange = selectedOption => {
        setSelectedProduct(selectedOption);
        getProductPrice(selectedOption.id);
    };

    const handleClientChange = selectedOption => {
        if(selectedOption.contactMail) { setGfaceEmail(selectedOption.contactMail); }
        setSelectedClient(selectedOption);
    };

    const handlePriceChange = selectedOption => {
        let productPrice = {...selectedOption};
        let price = new Decimal(selectedOption.price);
        productPrice.price = price.toFixed(2, Decimal.ROUND_HALF_EVEN);
        setPriceProduct(productPrice);
    };

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        if (inputValue) {
            fetchProduct(inputValue);
        }
    };

    const handleClientInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        if (inputValue) {
            fetchClient(inputValue);
        }
    };

    const addProductToInvoice = () => {
        // handlePriceChange({label: new Decimal(priceProduct.label).toFixed(2, Decimal.ROUND_HALF_EVEN), value: priceProduct.label, price: priceProduct.label});
        // console.log(priceProduct);
        let product ={};
        if(isEmpty(productPrices))
        {
            product = {...selectedProduct, price: new Decimal(priceProduct.label).toFixed(2, Decimal.ROUND_HALF_EVEN), quantity: quantityProduct};
        }
        else{
            product = {...selectedProduct, price: priceProduct.price, quantity: quantityProduct};
        }
        if(invoiceDescription)
        {
            product.descriptionProduct = invoiceDescriptionValue;
        }
        setInvoiceProducts([...invoiceProducts, product]);
        setSelectedProduct({label: '', value: ''});
        setQuantityProduct(0);
        dispatch(clearProductsPrices);

        setInvoiceDescription(false);
        setInvoiceDescriptionValue("");
    };

    const handleInputQuantityChange = (value, index) => {
        let productsOnInvoice = [...invoiceProducts];
        productsOnInvoice[index].quantity = value;
        setInvoiceProducts(productsOnInvoice);
    };

    const removeProduct = (index) => {
        let productsOnInvoice = [...invoiceProducts];
        setInvoiceProducts(productsOnInvoice.filter((item, indexFiltered) => indexFiltered !== index));
    };

    const validProduct = () => (!isEmpty(selectedProduct) && quantityProduct && quantityProduct>0);

    const handleSelect = (activeKey) => {
        setActiveKey(activeKey);
    };

    const getPaymentsTotal = () => {
        const cashTotal = cashAmount ?
            new Decimal(cashAmount)
            : new Decimal(0);

        const creditTotal = creditAmount ?
            new Decimal(creditAmount)
            : new Decimal(0);

        const cardTotal = creditCardAmount.amount ?
            new Decimal(creditCardAmount.amount)
            : new Decimal(0);

        return cashTotal.plus(creditTotal).plus(cardTotal).toFixed(2, Decimal.ROUND_HALF_EVEN);
    };

    const getPaymentsDecimal = () => {
        const cashTotal = cashAmount ?
            new Decimal(cashAmount)
            : new Decimal(0);

        const creditTotal = creditAmount ?
            new Decimal(creditAmount)
            : new Decimal(0);

        const cardTotal = creditCardAmount.amount ?
            new Decimal(creditCardAmount.amount)
            : new Decimal(0);

        return cashTotal.plus(creditTotal).plus(cardTotal);
    };

    const getTotal = () =>{
        return invoiceProducts.reduce((acc, item) => {
            return new Decimal(acc).plus(new Decimal(item.quantity).mul(item.price)).toFixed(2, Decimal.ROUND_HALF_EVEN);
        }, new Decimal(0).toFixed(2, Decimal.ROUND_HALF_EVEN));
    };

    const getTotalDecimal = () =>{
        return invoiceProducts.reduce((acc, item) => {
            console.log(item);
            return acc.plus(new Decimal(item.quantity).mul(item.price));
        }, new Decimal(0));
    };

    const getDifference = () =>{
        const total = getTotalDecimal();
        const paymentTot = getPaymentsDecimal();

        return total.minus(paymentTot).toFixed(2, Decimal.ROUND_HALF_EVEN);
    };

    const generateInvoiceRequest = () => {
        let invoice = {...invoiceRequest};
        let payments = [];
        const zero = new Decimal(0);
        let productsToAdd = invoiceProducts.map( product => {
            let response = {productId: product.id, quantity: product.quantity, price: new Decimal(product.price).toFixed(2, Decimal.ROUND_HALF_EVEN)};
            if(product.descriptionProduct)
            {
                response.descriptionProduct = product.descriptionProduct;
            }
            return response;
        });
        let today = new Date();
        let milliseconds = today.getMilliseconds();

        const cashTotal = cashAmount ?
            new Decimal(cashAmount)
            : new Decimal(0);

        const creditTotal = creditAmount ?
            new Decimal(creditAmount)
            : new Decimal(0);

        const cardTotal = creditCardAmount.amount ?
            new Decimal(creditCardAmount.amount)
            : new Decimal(0);

        if(cashTotal.comparedTo(zero)===1)
        {
            payments= [...payments, {paymentType: '1', amount: cashTotal.toFixed(2, Decimal.ROUND_HALF_EVEN)}]
        }

        if(creditTotal.comparedTo(zero)===1)
        {
            payments= [...payments, {paymentType: '3', amount: creditTotal.toFixed(2, Decimal.ROUND_HALF_EVEN)}]
        }

        if(cardTotal.comparedTo(zero)===1)
        {
            payments= [...payments, {paymentType: '4', amount: cardTotal.toFixed(2, Decimal.ROUND_HALF_EVEN), reference: creditCardAmount.reference, authorization: creditCardAmount.authorization, calculateSurcharge: false, cardId: "1", cardType:"1"}]
        }

        invoice.warehouseId = warehouseId;
        invoice.cash = cashId;
        invoice.clientId = selectedClient.id;
        invoice.date = Date.now();
        invoice.description = description;
        invoice.discount = discount;
        invoice.gface = gface;
        invoice.gfaceByEmail = gfaceByEmail; //true
        invoice.gfaceDownload = gfaceDownload;
        invoice.gfaceEmail = gfaceByEmail ? gfaceEmail : null;
        invoice.invoice = invoiceVal;
        invoice.normalDownload = normalDownload;
        invoice.total = getTotal();
        invoice.detail = productsToAdd;
        invoice.payment = payments;

        // if(invoiceDescription)
        // {
        //     invoice.descriptionProduct = invoiceDescriptionValue;
        // }

        setInvoiceRequest(invoice);
        dispatch(generateInvoice(invoice));
    };



    const createClientRequest = () => {
        const request = {provider: false, client: true, categories: [], nit: newClientNIT, name: newClientName, code: newClientCode, address: newClientAddress, contactName: newClientContactName,
            contactMail: newClientContactMail, contactPhone: newClientContactPhone, contactCellphone: newClientContactPhone};
        dispatch(createClient(request));
        setCreateClientFlag(false);
    };

    const getSuccessInvoiceModal = (resetInvoice) =>{
        let footBtn =
            <div>
                <Row>
                    <Col xs={12} md={6}>
                        {/*<div style={{textAlign: 'left'}}>*/}
                        {/*    <Button className={'custom-button modal-button-footer'} onClick={(e) => this.back(e)}> <i className={'fas fa-times'}/> Cancelar</Button>*/}
                        {/*</div>*/}
                    </Col>

                    <Col xs={12} md={6}>
                        <div style={{textAlign: 'right'}}>
                            <Button className={'custom-button modal-button-footer'} onClick={(e) => resetState()}>Aceptar <i className={'fas fa-chevron-right'}/></Button>
                        </div>
                    </Col>
                </Row>
            </div>;

        return <CustomModal type={'success'} title={'Operacion exitosa'} show={resetInvoice} footer={footBtn}>
            <div style={{textAlign: 'justify'}}>
                Factura generada exitosamente.
            </div>
        </CustomModal>;
    };

    const createClientModal = () =>{
        let footBtn =
            <div>
                <Row>
                    <Col xs={12} md={6}>
                        <div style={{textAlign: 'left'}}>
                            <Button className={'custom-button-danger modal-button-footer'} onClick={ () => setCreateClientFlag(false)}> <i className={'fas fa-times'}/> Cancelar</Button>
                        </div>
                    </Col>

                    <Col xs={12} md={6}>
                        <div style={{textAlign: 'right'}}>
                            <Button className={'custom-button-green modal-button-footer'} onClick={(e) => createClientRequest()}
                                disabled={isEmpty(newClientNIT) || isEmpty(newClientName)}>
                                Aceptar <i className={'fas fa-chevron-right'}/>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>;

        return <CustomModal type={'info'} title={'Crear cliente'} show={createClientFlag} footer={footBtn}>
            <div style={{textAlign: 'justify'}}>
                <div className={'cv-input-wrapper'}>
                    <div className={'label'}>
                        NIT: <span className={'required'}>*</span>
                    </div>
                    <input type="text" className={'cv-input'} placeholder={"NIT"}  value={newClientNIT} onChange={(value)=> setNewClientNIT(value.target.value.toUpperCase())}/>
                </div>
                <div className={'cv-input-wrapper'}>
                    <div className={'label'}>
                        Nombre: <span className={'required'}>*</span>
                    </div>
                    <input type="text" className={'cv-input'} placeholder={"Nombre"}  value={newClientName} onChange={(value)=> setNewClientName(value.target.value)}/>
                </div>
                <div className={'cv-input-wrapper'}>
                    <div className={'label'}>
                        Codigo:
                    </div>
                    <input type="text" className={'cv-input'} placeholder={"Codigo"}  value={newClientCode} onChange={(value)=> setNewClientCode(value.target.value)}/>
                </div>
                <div className={'cv-input-wrapper'}>
                    <div className={'label'}>
                        Direccion:
                    </div>
                    <input type="text" className={'cv-input'} placeholder={"Direccion"}  value={newClientAddress} onChange={(value)=> setNewClientAddress(value.target.value)}/>
                </div>
                <div className={'cv-input-wrapper'}>
                    <div className={'label'}>
                        Nombre de Contacto:
                    </div>
                    <input type="text" className={'cv-input'} placeholder={"Nombre de Contacto"}  value={newClientContactName} onChange={(value)=> setNewClientContactName(value.target.value)}/>
                </div>
                <div className={'cv-input-wrapper'}>
                    <div className={'label'}>
                        Correo:
                    </div>
                    <input type="text" className={'cv-input'} placeholder={"Correo"}  value={newClientContactMail} onChange={(value)=> setNewClientContactMail(value.target.value)}/>
                </div>
                <div className={'cv-input-wrapper'}>
                    <div className={'label'}>
                        Telefono:
                    </div>
                    <input type="text" className={'cv-input'} placeholder={"Telefono"}  value={newClientContactPhone} onChange={(value)=> setNewClientContactPhone(value.target.value)}/>
                </div>

            </div>
        </CustomModal>;
    };

    return (
        <div>
            {getSuccessInvoiceModal(resetInvoice)}
            {createClientModal()}
                <Row>
                    <Col xs={12} md={12} className={'padding-0'}>
                        <div className={'panel'}>
                            <h3 className={'blue montserrat f500'}> <b>Datos de Factura</b> </h3>
                            <Row>
                                    <Col xs={12} md={12}>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <div style={{textAlign: 'left'}}>
                                                    <h2 className={'blue montserrat f500 fs-16'}><b>Datos del cliente</b></h2>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={3}>
                                                <div className={'cv-input-wrapper'}>
                                                    <div className={'label'}>
                                                        NIT: <span className={'required'}>*</span>
                                                    </div>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        name="color"
                                                        isClearable={false}
                                                        isSearchable={true}
                                                        options={clients.map( client => ({...client, label: client.nit, value: client.id}))}
                                                        value={selectedClient}
                                                        onChange={handleClientChange}
                                                        onInputChange={debounce(handleClientInputChange, 800)}
                                                        filterOption={filterClientOptions}
                                                        placeholder={"Buscar cliente"}
                                                        isLoading={isLoadingClients}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={12} md={5}>
                                                <div className={'cv-input-wrapper'}>
                                                    <div className={'label'}>
                                                        Nombre: <span className={'required'}>*</span>
                                                    </div>
                                                    <input type="text" className={'cv-input'} placeholder={"Nombre"} name="Nombre" value={selectedClient.name} disabled={true}/>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <div className={'cv-input-wrapper'}>
                                                    <div className={'label'}>
                                                        Descargar Factura:
                                                    </div>
                                                    {/*<input type="text" className={'cv-input'} placeholder={"Direccion"} name="Direccion" value={userAddress} onChange={ (value)=> setUserAddress(value.target.value) }/>*/}
                                                    <Input
                                                        className={'cv-input'} placeholder={"Direccion"}
                                                        name='actual'
                                                        onChange={(value)=> {setDownloadPDF(Boolean(value.target.checked))}}
                                                        checked={downloadPDF}
                                                        type='checkbox'
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <div className={'cv-input-wrapper'}>
                                                    <div style={{textAlign: 'center'}}>
                                                        <Button className={'custom-button-sm'} onClick={ () => setCreateClientFlag(true)}>Nuevo Cliente <i className={'fas fa-plus'}/></Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={2}>
                                                <div className={'cv-input-wrapper'}>
                                                    <div className={'label'}>
                                                        Enviar factura por correo:
                                                    </div>
                                                    {/*<input type="text" className={'cv-input'} placeholder={"Direccion"} name="Direccion" value={userAddress} onChange={ (value)=> setUserAddress(value.target.value) }/>*/}
                                                    <Input
                                                        className={'cv-input'} placeholder={"FacturaCorreo"}
                                                        name='actual'
                                                        onChange={(value)=> {setGfaceByEmail(Boolean(value.target.checked))}}
                                                        checked={gfaceByEmail}
                                                        type='checkbox'
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className={'cv-input-wrapper'}>
                                                    <div className={'label'}>
                                                        Correo Electronico: <span className={'required'}>*</span>
                                                    </div>
                                                    <input type="text" className={'cv-input'} placeholder={"Email"} name="email" value={gfaceEmail} onChange={(value)=> setGfaceEmail(value.target.value)}/>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={7}>

                                            </Col>


                                        </Row>

                                    </Col>
                            </Row>

                            <hr/>

                            <Row>
                                <Col xs={12} md={12}>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <div style={{textAlign: 'left'}}>
                                                <h2 className={'blue montserrat f500 fs-16'}><b>Agregar Producto</b></h2>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <div className={'cv-input-wrapper'}>
                                                <div className={'label'}>
                                                    Producto: <span className={'required'}>*</span>
                                                </div>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    name="color"
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    options={products.map( product => ({...product, label: product.name, value: product.id}))}
                                                    value={selectedProduct}
                                                    onChange={handleChange}
                                                    onInputChange={debounce(handleInputChange, 800)}
                                                    filterOption={filterOptions}
                                                    placeholder={"Buscar producto"}
                                                    isLoading={isLoadingProducts}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <div className={'cv-input-wrapper'}>
                                                <div className={'label'}>
                                                    Precio: <span className={'required'}>*</span>
                                                </div>
                                                {
                                                    isLoadingProductPrice ? <div className={'input-wrapper'} style={{display: 'flex'}}>
                                                            <i className={'fa fa-spinner fa-spin'} style={{fontSize: 40, textAlign:'center', width:'100%', color: '#3498DB'}}/>
                                                        </div> :
                                                    !isEmpty(productPrices) ?
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            name="color"
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            options={productPrices.map( price => ({...price, label: price.listData.name +', precio: Q. '+new Decimal(price.price).toFixed(2, Decimal.ROUND_HALF_EVEN), value: price.price}))}
                                                            value={priceProduct}
                                                            onChange={handlePriceChange}
                                                            placeholder={"Seleccione Precio"}
                                                            isLoading={isLoadingProductPrice}
                                                        />:
                                                        // <div className={'label'}>
                                                        //     Este producto no tiene precios configurados
                                                        // </div>
                                                        <div className={'cv-input-wrapper'}>
                                                            <input type="number" className={'cv-input'} placeholder={"Precio"} name="Precio" value={priceProduct.label} onChange={ (value)=> {setPriceProduct({label: value.target.value})} }/>
                                                        </div>
                                                }

                                            </div>
                                        </Col>
                                        <Col xs={12} md={2}>
                                            <div className={'cv-input-wrapper'}>
                                                <div className={'label'}>
                                                    Cantidad: <span className={'required'}>*</span>
                                                </div>
                                                <input type="number" className={'cv-input'} placeholder={"Cantidad"} name="Cantidad" value={quantityProduct} onChange={ (value)=> {if (value.target.value>=0) setQuantityProduct(value.target.value)} }/>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={1}>
                                            <div className={'cv-input-wrapper'}>
                                                <div style={{textAlign: 'right'}}>
                                                    <Button className={'custom-button-sm'} onClick={addProductToInvoice} disabled={isEmpty(selectedProduct.id) && isEmpty(priceProduct.id) && quantityProduct===0}>Agregar <i className={'fas fa-plus'}/></Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} md={2}>
                                    <div className={'cv-input-wrapper'}>
                                        <div className={'label'}>
                                            Descripción de Producto
                                        </div>
                                        {/*<input type="text" className={'cv-input'} placeholder={"Direccion"} name="Direccion" value={userAddress} onChange={ (value)=> setUserAddress(value.target.value) }/>*/}
                                        <Input
                                            className={'cv-input'} placeholder={"FacturaCorreo"}
                                            name='actual'
                                            onChange={(value)=> {setInvoiceDescription(Boolean(value.target.checked))}}
                                            checked={invoiceDescription}
                                            type='checkbox'
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} md={10}>
                                    {invoiceDescription ?
                                        <div className={'cv-input-wrapper'}>
                                            <div className={'label'}>
                                                Descripción de Factura: <span className={'required'}>*</span>
                                            </div>
                                            {/*<input type="text" className={'cv-input'} placeholder={"Descripción"} name="description" value={invoiceDescriptionValue} onChange={(value)=> setInvoiceDescriptionValue(value.target.value)}/>*/}
                                            <textarea className="form-control" id="Descripción" rows="3" name='description' value={invoiceDescriptionValue} onChange={(value) => setInvoiceDescriptionValue(value.target.value)}/>
                                        </div> :
                                        null
                                    }
                                </Col>

                            </Row>

                            <hr/>

                            <Row>
                                <Col xs={12} md={12}>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            {
                                                !isEmpty(invoiceProducts) ?
                                                    <Table responsive striped bordered hover>
                                                        <thead>
                                                        <tr>
                                                            <th className={'col-md-1'}>Codigo</th>
                                                            <th className={'col-md-6'}>Nombre</th>
                                                            <th className={'col-md-3'}>Precio</th>
                                                            <th className={'col-md-1'}>Cantidad</th>
                                                            <th className={'col-md-1'}>Opciones</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            invoiceProducts.map((item, index) => {
                                                                const {code, name, price, quantity, id} = item;
                                                                return (
                                                                    <tr key={id}>
                                                                        <td>{code}</td>
                                                                        <td>{name}</td>
                                                                        <td>{price}</td>
                                                                        <td><input type="number" className={'cv-input'} placeholder={"Cantidad"}  value={quantity} onChange={(value)=> handleInputQuantityChange(value.target.value, index)}/></td>
                                                                        <td>
                                                                            <div style={{textAlign: 'center'}}>
                                                                                <Button className={'custom-button-danger-sm'} onClick={ ()=> removeProduct(index) }>
                                                                                    Eliminar <i className="fas fa-trash" aria-hidden="true"/>
                                                                                </Button>
                                                                            </div>

                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        </tbody>
                                                    </Table>: <h4 className={'blue montserrat f500 fs-16'}>No hay productos en la factura</h4>
                                            }
                                        </Col>
                                    </Row>

                                </Col>

                            </Row>

                            <hr/>

                            <Row>
                                <Col xs={12} md={12}>
                                    <div style={{textAlign: 'left'}}>
                                        <h2 className={'blue montserrat f500 fs-16'}><b>Metodos de pago</b></h2>
                                    </div>
                                    <PanelGroup
                                        accordion
                                        id="accordion-controlled-example"
                                        activeKey={activeKey}
                                        onSelect={handleSelect}
                                    >
                                        <Panel eventKey="1">
                                            <Panel.Heading>
                                                <Panel.Title toggle><h4 className={'blue montserrat f500 fs-16'}>Efectivo <i className={'fas fa-money-bill-alt'}/> </h4></Panel.Title>
                                            </Panel.Heading>
                                            <Panel.Body collapsible>
                                                <Row>
                                                    <Col xs={12} md={4}>
                                                        <div className={'cv-input-wrapper'}>
                                                            <div className={'label'}>
                                                                Efectivo: <span className={'required'}>*</span>
                                                            </div>
                                                            <input id={"Efectivo"} type="number" className={'cv-input'} placeholder={"Efectivo"} name={"Efectivo"} value={cashAmount} onChange={ (value)=> setCashAmount(value.target.value) }/>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} md={8}>
                                                    </Col>
                                                </Row>
                                            </Panel.Body>
                                        </Panel>
                                        <Panel eventKey="2">
                                            <Panel.Heading>
                                                <Panel.Title toggle><h4 className={'blue montserrat f500 fs-16'}>Crédito <i className={'fas fa-address-card'}/> </h4></Panel.Title>
                                            </Panel.Heading>
                                            <Panel.Body collapsible>
                                                <Row>
                                                    <Col xs={12} md={4}>
                                                        <div className={'cv-input-wrapper'}>
                                                            <div className={'label'}>
                                                                Crédito: <span className={'required'}>*</span>
                                                            </div>
                                                            <input id={"Credito"} type="number" className={'cv-input'} placeholder={"Credito"} name={"Credito"} value={creditAmount} onChange={ (value)=> setCreditAmount(value.target.value) }/>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} md={8}>
                                                    </Col>
                                                </Row>
                                            </Panel.Body>
                                        </Panel>
                                        <Panel eventKey="3">
                                            <Panel.Heading>
                                                <Panel.Title toggle><h4 className={'blue montserrat f500 fs-16'}>Tarjeta de Crédito/Débito <i className={'fas fa-credit-card'}/> </h4></Panel.Title>
                                            </Panel.Heading>
                                            <Panel.Body collapsible>
                                                <Row>
                                                    <Col xs={12} md={4}>
                                                        <div className={'cv-input-wrapper'}>
                                                            <div className={'label'}>
                                                                Tarjeta de Crédito/Débito: <span className={'required'}>*</span>
                                                            </div>
                                                            <input id={"creditCard"} type="number" className={'cv-input'} placeholder={"Tarjeta de Crédito/Débito"} name={"creditCard"} value={creditCardAmount.amount} onChange={ (value)=> setCreditCardAmount({...creditCardAmount, amount: value.target.value}) }/>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <div className={'cv-input-wrapper'}>
                                                            <div className={'label'}>
                                                                Referencia: <span className={'required'}>*</span>
                                                            </div>
                                                            <input id={"reference"} type="text" className={'cv-input'} placeholder={"Referencia"} name={"reference"} value={creditCardAmount.reference} onChange={ (value)=> setCreditCardAmount({...creditCardAmount, reference: value.target.value}) }/>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <div className={'cv-input-wrapper'}>
                                                            <div className={'label'}>
                                                                Autorización: <span className={'required'}>*</span>
                                                            </div>
                                                            <input id={"authorization"} type="text" className={'cv-input'} placeholder={"Autorización"} name={"authorization"} value={creditCardAmount.authorization} onChange={ (value)=> setCreditCardAmount({...creditCardAmount, authorization: value.target.value}) }/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Panel.Body>
                                        </Panel>
                                    </PanelGroup>
                                </Col>
                            </Row>

                            <hr/>

                            <Row>
                                <Col xs={12} md={2}>
                                    <div style={{textAlign: 'left'}}>
                                        <h2 className={'blue montserrat f500 fs-16'}>Efectivo: <b>Q. { cashAmount?
                                            new Decimal(cashAmount).toFixed(2, Decimal.ROUND_HALF_EVEN)
                                            : new Decimal(0).toFixed(2, Decimal.ROUND_HALF_EVEN)
                                        }
                                        </b></h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={2}>
                                    <div style={{textAlign: 'left'}}>
                                        <h2 className={'blue montserrat f500 fs-16'}>Crédito: <b>Q. { creditAmount ?
                                            new Decimal(creditAmount).toFixed(2, Decimal.ROUND_HALF_EVEN)
                                            : new Decimal(0).toFixed(2, Decimal.ROUND_HALF_EVEN)
                                        }
                                        </b></h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={2}>
                                    <div style={{textAlign: 'left'}}>
                                        <h2 className={'blue montserrat f500 fs-16'}>Tarjeta: <b>Q. { creditCardAmount.amount ?
                                            new Decimal(creditCardAmount.amount).toFixed(2, Decimal.ROUND_HALF_EVEN)
                                            : new Decimal(0).toFixed(2, Decimal.ROUND_HALF_EVEN)
                                        }
                                        </b></h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={3}>
                                    <div style={{textAlign: 'right'}}>
                                        <h2 className={'green montserrat f500 fs-16'}><b>TOTAL PAGO: Q. {
                                            getPaymentsTotal()
                                        }
                                        </b></h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={3}>
                                    <div style={{textAlign: 'right'}}>
                                        <h2 className={'red montserrat f500 fs-16'}><b>DIFERENCIA: Q. {
                                            getDifference()
                                        }
                                        </b></h2>
                                    </div>
                                </Col>
                            </Row>


                            <hr/>


                            <Row>
                                <Col xs={12} md={12}>
                                    <div style={{textAlign: 'right'}}>
                                        <h2 className={'blue montserrat f500'}>Total: Q. <b>{
                                            invoiceProducts.reduce((acc, item) => {
                                            return new Decimal(acc).plus(new Decimal(item.quantity).mul(item.price)).toFixed(2, Decimal.ROUND_HALF_EVEN);
                                        }, new Decimal(0).toFixed(2, Decimal.ROUND_HALF_EVEN))
                                        }
                                        </b></h2>
                                    </div>
                                </Col>
                            </Row>
                            <Row>

                                <Col xs={12} md={12}>
                                    <div style={{textAlign: 'right'}}>
                                       { isLoadingGenerateInvoices ?
                                           <div className={'input-wrapper'} style={{display: 'flex'}}>
                                               <i className={'fa fa-spinner fa-spin'} style={{fontSize: 40, textAlign:'center', width:'100%', color: '#3498DB'}}/>
                                           </div> :
                                        <Button className={'custom-button-green'} onClick={generateInvoiceRequest}>
                                            Generar Factura <i className={'fas fa-chevron-right'}/>
                                        </Button>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>



        </div>
    );
}

export default Sales;