import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {loadingSelector} from "../../selectors/loading.selector";
import {actionTypes, validateToken} from "../../actions/user.actions";
import LoginContainer from '../Login/LoginContainer';
import logo from '../Login/Logo.png';
import '../Login/login.css'

class EmailConfirmation extends Component {

    state = {      
        token: ''
    };
    
    componentDidMount () {
        const {token} = this.props.match.params;
        this.props.validateToken(token);
        this.setState({token: token})      
    }

    render() {                                                               
        const {isValidateTokenLoading} = this.props;

        return (
            <LoginContainer>
                <div className={'tab-content'}>
                    <div className={"login-form-title"} style={{width:300}}>
                        <h3 className={'blue f400'}>Bienvenido</h3>
                        <h5 className={'open-sans'}>Espera mientras confirmamos tu cuenta</h5>
                    </div>
                    <form autoComplete={"off"}>
                        <div className={'input-wrapper'} style={{display: !isValidateTokenLoading? 'none': 'flex'}}>
                            <i className={'fa fa-spinner fa-spin'} style={{fontSize: 40, textAlign:'center', width:'100%', color: '#3498DB'}}/>
                        </div>
                        <div className={'button-container'} style={{display: isValidateTokenLoading? 'none': 'flex'}}>
                            Tu correo Electrónico ha sido validado exitosamente...
                            <Link to={'/'} className={"txt2"} style={{marginTop: 25, cursor:'pointer'}}>
                                <span className={'blue'}>ACCEDER A MI CUENTA</span>
                            </Link>
                        </div>
                    </form>
                </div>
            </LoginContainer>
        );

    }

}

const mapStateToProps = state => ({   
    isValidateTokenLoading: loadingSelector([actionTypes.VALIDATE_TOKEN])(state),
});

const mapDispatchToProps = dispatch => ({    
    validateToken: (token) => dispatch(validateToken(token))
});

export default connect(mapStateToProps,mapDispatchToProps,)(EmailConfirmation);
